import { observable, computedFrom, bindable, autoinject, DOM } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { Logger } from 'helpers/logger';
import * as Utils from 'helpers/utils';
import { ErrorManager } from 'helpers/errorManager';

import { Database, Path, ChildPath, Action, Query } from 'services/database';
import { Authentication } from 'services/authentication';

import { Curriculum, User, defaultCurriculum } from 'definitions';

@autoinject
export class DashboardPage {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  //DOC: oggetto che contiene la lista dei curricula
  @bindable curricula: Curriculum[] = [];

  //DOC: indica se sta caricando i curricula
  @bindable isLoadingData: boolean = true;

  @bindable name: string = "";

  //DOC: array di query firebase che si utilizzano nel modello
  public queries: Query[] = [];

  constructor(private db: Database, private auth: Authentication, private router: Router) { }

  bind() {
    //DOC: sottoscrivo eventi: ON sui curricula, ON sull'utente
    this.queries = [
      this.db.query(Path.curricula).orderByChild(ChildPath.idUser).equalTo(this.auth.getCurrentUser().uid),
      this.db.query(Path.users).orderByKey().equalTo(this.auth.getCurrentUser().uid)
    ];
    this.queries[0].on(Action.onValue, (data: any) => this.onCurriculumReading.call(this, data));
    this.queries[0].on('value', function (snapshot) {
      //console.log("modificato", snapshot);
    });
    //this.queries[0].on(Action.onAdded, (data: any) => this.onCurriculumAdded.call(this, data));
    //this.queries[0].on(Action.onRemoved, (data: any) => this.onCurriculumRemoved.call(this, data));

    this.queries[1].once(Action.onValue, (data: any) => this.onUserRead.call(this, Utils.toArrayOfObjects(data.val())[0])); //NOTE: prendo il primo elemento dell'array (ma ce ne è solo uno)
    //this.queries.forEach(q => console.log(q.toString()));

    console.log("subscribed");
    window.scrollTo(0, 0);
  }

  unbind() {
    //DOC: disinscrivo tutti gli eventi
    console.log("unscribed");
  }

  //DOC: callback alla lettura dei curricula
  public onCurriculumReading(data: any) {
    this.logger.log("Curriculum letto");
    //NOTE: trasformo data in array di oggetti e aggiorno i cvs
    this.curricula = Utils.toArrayOfObjects(data.val());
    this.isLoadingData = false;
  }
  /*
    //DOC: callback alla lettura dei curricula
    public onCurriculumAdded(data: any) {
      this.logger.log("Curriculum effettivamente aggiunto al DB");
      var e = data.val() as Curriculum;
      e.id = data.key;
      // aggiungo il cv alla lista dei curricula
      if (this.curricula.some(_ => _.id == e.id))
        throw new Error("Il curriculum che si vuole aggiungere è già presente");
      this.curricula.push(e);
      this.isLoadingData = false;
    }
    //DOC: callback alla lettura dei curricula
    public onCurriculumRemoved(data: any) {
      this.logger.log("Curriculum effettivamente rimosso dal DB");
      // elimino il curriculum dalla lista dei curricula
      var index = this.curricula.findIndex(c => c.id == data.key);
      if (index == -1)
        throw new Error("Il curriculum che si vuole eliminare non è stato trovato");
      this.curricula.splice(index, 1);
    }
    */
  //DOC: callback richiamata quando ottengo i dati dell'utente
  public onUserRead(data: any) {
    data = data as User;
    this.name = data.name || this.name;
  }

  //DOC: elimina un curriculum
  openCurriculum(id: string) {
    this.router.navigateToRoute("cv", { id: id });
  }

  //DOC: elimina un curriculum
  deleteCurriculum(c: Curriculum) {
    this.logger.log("Cancello il curriculum ", c.id);
    this.db.deleteCurriculum(c.title, c.id, this.auth.getCurrentUser().uid);
  }

  //DOC: crea un nuovo curriculum
  createCurriculum() {
    this.logger.log("Creo un nuovo curriculum ");
    let c: Curriculum = { ...defaultCurriculum, uid: this.auth.getCurrentUser().uid, lastUpdate: (new Date()).getTime() };
    this.db.appendCurriculum(c);
  }

  //DOC: restituisce true se ci sono curricula, false altrimenti
  @computedFrom("curricula.length")
  get hasCurricula() {
    return this.curricula.length > 0;
  }

}
