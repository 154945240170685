import { bindable, computedFrom, bindingMode } from "aurelia-framework";

export class LoadingComponent {
  //DOC: stato del componente, se true è visibile, altrimenti è nascosto
  @bindable isLoading: boolean;
  //DOC: testo facoltativo sotto lo spinner
  @bindable loadingText: string;

  //DOC: imposta un timeout in ms scaduto il quale imposta isLoading a false
  @bindable timeout: number = 0;
  private timeoutDec: number = 0;
  //DOC: restituisce la percentuale di tempo trascorso (read only lato view)
  @bindable({ defaultBindingMode: bindingMode.toView }) timeoutPerc: number;
  /*NOTE: è possibile utilizzare il valore di timeoutPerc all'interno del messaggio
  view-model.ref="loading" loading-text="${'Caricamento '+loading.timeoutPerc+'%' }" timeout.bind="2000" */

  bind() {
    if (this.timeout != 0) {
      this.timeoutDec = this.timeout;
      let bitOfTime: number;
      if (this.timeout <= 1000) bitOfTime = 50;
      else bitOfTime = 100;
      let idTimeout = setInterval(() => {
        this.timeoutDec -= bitOfTime;
        this.timeoutPerc = 100 - Math.round(this.timeoutDec / this.timeout * 100);
        //console.log(this.timeoutDec, " --- ", this.timeoutPerc + "%");
        if (this.timeoutDec <= 0) {
          //console.log("fine");
          clearInterval(idTimeout);
        }
      }, bitOfTime);
    }
  }

  @computedFrom('loadingText')
  get isLoadingTextValid(): boolean {
    return this.loadingText !== null && this.loadingText !== "";
  }


}
