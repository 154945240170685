import { observable, computedFrom, bindable, autoinject } from 'aurelia-framework';
import { Router, RouteConfig, RouterConfiguration, NavigationInstruction } from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';

import { Curriculum, User } from 'definitions';
import { Database, Path, ChildPath, Action, Query } from 'services/database';
import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';
import * as Utils from 'helpers/utils';

//import './css/edit-cv-page.css';

@autoinject
export class EditCvPage {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = (new Logger()).setStyle(Logger.colors.orange, Logger.colors.white).setDefaultPrefix("EditorCVPage");

  //DOC: oggetto curriculum del modello DB
  @bindable curriculum: Curriculum = null;
  //DOC: id del cv nel DB
  @bindable idCurriculum: string = "";

  //DOC: indica se l'elemento è nello stato di EDIT, se false è in stato di VIEW
  @bindable canEdit: boolean = true;
  //DOC: indica se in stato di EDIT si sta modificando
  @bindable isEditing: boolean = false;

  //DOC: #id elemento su cui scrollare
  @bindable goto: string;

  //DOC: indica se il curriculum è stato completamente caricato
  @bindable isLoadingCompleted: boolean = false;

  //DOC: array di query firebase che si utilizzano nel modello
  public queries: Query[] = [];

  constructor(private db: Database, private router: Router) { }

  bind() {
    //this.router.currentInstruction.config.canEdit = this.toggleEditMode;
    //DOC: sottoscrivo eventi: ON sul curriculum
    this.queries = [this.db.query(Path.curricula).orderByKey().equalTo(this.idCurriculum)];
    this.queries[0].once(Action.onValue, (data: any) => this.onCurriculumReading.call(this, data));
    //this.queries.forEach(q => console.log(q.toString()));

    window.scrollTo(0, 0);
  }
  unbind() { }

  activate(params: any, routeConfig: RouteConfig, navigationInstruction: NavigationInstruction) {
    //TODO: se l'id passato non corrisponde a nessun cv dell'utente allora ritorno alla dashboard
    if (params.id == null)
      this.router.navigateToRoute('dashboard');
    else
      this.idCurriculum = params.id;
    this.goto = params.goto;
  }

  deactivate() {
    //DOC: disinscrivo tutti gli eventi
    this.queries.forEach(q => q.off());
  }


  //DOC: callback richiamata al completamento del caricamento del Curriculum
  public onCurriculumReading(data: any) {
    if (!data.exists()) {
      this.logger.warning("Cercato un curriculum che non esiste (" + this.idCurriculum + "), reindirizzo alla dashboard");
      this.router.navigateToRoute('dashboard');
      return;
    }
    this.curriculum = Utils.toArrayOfObjects(data.val())[0] as Curriculum;
  }

  //DOC: callback al completamento del caricamento di tutte le categorie ed elementi
  public isLoadingCompletedChanged(_new: boolean, _old: boolean) {
    if (_new && this.goto != null && this.goto != "") {
      document.getElementById(this.goto).scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById(this.goto).children[0].classList.add('goto-focus');
    }
  }

}
