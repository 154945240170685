import { autoinject, PLATFORM } from 'aurelia-framework';
import { Router, RouteConfig, NavigationInstruction, RouterConfiguration } from 'aurelia-router';

import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';

import "./css/confirms-cv-page.css"

@autoinject
export class ConfirmsCvPage {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  constructor(private router: Router) { }

  configureRouter(config: RouterConfiguration, router: Router) {
    config.map([{
      route: [''], name: 'confirms',
      moduleId: PLATFORM.moduleName('pages/CvSubpages/ConfirmsCvSubpages/ConfirmsListPage'),
      nav: false, title: 'Confirms'
    },
    {
      route: 'new/:new', name: 'confirms/new',
      moduleId: PLATFORM.moduleName('pages/CvSubpages/ConfirmsCvSubpages/NewConfirmPage'),
      nav: false, title: 'New Confirm'
    }]);
    config.mapUnknownRoutes({
      route: 'confirms',
      moduleId: PLATFORM.moduleName('pages/CvSubpages/ConfirmsCvSubpages/ConfirmsListPage')
    });
    this.router = router;
  }
}

