import { Database, Path, ChildPath, Action, Query } from 'services/database';
import { Authentication } from 'services/authentication';
import { autoinject, computedFrom, bindable } from 'aurelia-framework';
import { ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { BootstrapFormRenderer } from 'helpers/validationFormRender';

import { Storage_, LocalResources } from 'services/storage';
import { User } from 'definitions';

import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';
import * as Utils from 'helpers/utils';

import { DateFormatValueConverter } from 'converters/dateFormat';

@autoinject
export class ProfilePage {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  //DOC: dati dell'utente ottenuti dal database
  @bindable name: string = "";
  @bindable lastname: string = "";
  @bindable dob: number = 0;
  @bindable dor: number = 0;
  @bindable gender: string = "";
  @bindable address: string = "";
  @bindable photoURL: string = "";
  //DOC: nuova immagine del profilo che si sta caricando
  @bindable newProfileImage: any = [];
  //DOC: progresso nel caricamento della nuova immagine del profilo
  @bindable uploadProgress: number = -1;
  //DOC: indica se si stanno caricando i dati
  @bindable isLoadingData: boolean = true;

  //DOC: array di query firebase che si utilizzano nel modello
  public queries: Query[] = [];

  //DOC: oggetto controller della validazione di forms
  public validationController: ValidationController;

  constructor(private storage: Storage_, private auth: Authentication, private db: Database,
    private controllerFactory: ValidationControllerFactory) {
    this.validationController = controllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  bind() {
    if (this.auth.getCurrentUser().photoURL != "" && this.auth.getCurrentUser().photoURL != null)
      this.photoURL = this.auth.getCurrentUser().photoURL;
    else
      this.photoURL = LocalResources.profile.default;

    this.queries = [this.db.query(Path.users).orderByKey().equalTo(this.auth.getCurrentUser().uid)];
    this.queries[0].on(Action.onValue, (data: any) => this.onUserRead.call(this, Utils.toArrayOfObjects(data.val())[0]));//NOTE: prendo il primo elemento dell'array (ma ce ne è solo uno)
    window.scrollTo(0, 0);
  }

  unbind() {
    //DOC: disinscrivo tutti gli eventi
    this.queries.forEach(q => q.off());
  }

  //DOC: metodo richiamato quando newProfileImage ha il valore della nuova immagine
  public newProfileImageChanged(_old, _new) {
    if (this.newProfileImage.length == 0) return;
    //FIXME: non faccio il controllo dell'estensione (che dovrei fare soprattutto in backend)
    this.storage.uploadProfilePicture(this.auth.getCurrentUser().uid, this.newProfileImage[0], this.onProgress, this.onCompleted, this);

  }
  //DOC: callback richiamata al progresso del caricamento
  onProgress(snapshot: any) {
    var progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
    this.uploadProgress = progress;
  }
  //DOC: callback richiamata al completamento del caricamento dell'immagine
  onCompleted(link: any) {
    this.photoURL = link;
    this.uploadProgress = -1;
    this.auth.updateProfile({ photoURL: this.photoURL });
  }

  //DOC: callback richiamata quando ottengo i dati dell'utente
  public onUserRead(data: any) {
    this.logger.log("Dati dell'utente letti o aggiornati");
    data = data as User;
    this.name = data.name || this.name;
    this.lastname = data.lastname || this.lastname;
    this.dob = data.dob || this.dob;
    this.dor = data.dor || this.dor;
    this.gender = data.gender || this.gender;
    this.address = data.address || this.address;
    this.isLoadingData = false;
  }

  //DOC: restituisce i millisecondi in formato unix della data passata e 0 se non è valida  
  public toMillisecond(date: string): number {
    if (!date || date == "") return 0;
    return parseInt((new DateFormatValueConverter()).toView(date, "x"), 10);
  }

  //DOC: al submit della form valido i campi ed aggiorno il profilo
  submit() {
    let d = {
      name: this.name,
      lastname: this.lastname,
      dob: this.dob,
      dor: this.dor,
      gender: this.gender,
      address: this.address
    };
    this.validationController
      .validate()
      .then(r => {
        if (r.valid) {
          this.logger.log("Aggiornamento dati dell'utente");
          console.log(d);
          //NOTE: mantengo aggiornato il displayName in Auth come name + <space> + lastname
          this.auth.updateProfile({ displayName: d.name + " " + d.lastname });
          this.db.updateUser(this.auth.getCurrentUser().uid, d);
        }
      });
  }
}

ValidationRules
  .ensure('name').required()
  .ensure('lastname').required()
  .ensure('dob').required()
  .ensure('gender').required()
  .ensure('address').required()
  .on(ProfilePage);


