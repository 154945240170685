import { autoinject, bindable, computedFrom } from 'aurelia-framework'
import { Router } from 'aurelia-router';
import { Element } from 'definitions';

import { Database, Path, ChildPath, Action, Query } from 'services/database';

import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger'

import './css/element-component.css';

@autoinject
export class ElementComponent {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  //DOC: oggetto elemento del modello DB
  @bindable element: Element;

  //DOC: indica se l'elemento è nello stato di EDIT, se false è in stato di VIEW
  @bindable canEdit: boolean = true;
  //DOC: indica se si sta modificano l'elemento
  @bindable editing: boolean = true;

  //DOC: proprietà settata da padre, se true è la prima categoria in elenco
  @bindable inFirstPosition: boolean = false;
  //DOC: proprietà settata da padre, se true è l'ultima categoria in elenco
  @bindable inLastPosition: boolean = false;

  //DOC: array di query firebase che si utilizzano nel modello
  public queries: Query[] = [];

  //DOC: eventi sollevati per il cambiamento di posizione degli elementi
  @bindable public onMoveUpPosition: (p: any) => void;
  @bindable public onMoveDownPosition: (p: any) => void;

  //DOC: eventi sollevati in CategoryComponent per l'aggiunta o l'eliminazione degli elementi
  @bindable public onAddingElement: (position: any) => void;
  @bindable public onDeletingElement: (idElement: string, idCategory: string) => void;

  constructor(private db: Database, private router: Router) { }

  bind() {
    //DOC: sottoscrivo eventi: onChanged su Element
    this.queries = [this.db.query(Path.elements).orderByKey().equalTo(this.element.id)];
    this.queries[0].on(Action.onChanged, (data: any) => this.onElementChanged.call(this, data));
    //this.queries.forEach(q => console.log(q.toString()));
  }
  unbind() {
    //DOC: disinscrivo tutti gli eventi
    this.queries.forEach(q => q.off());
  }

  //DOC: salva le modifiche all'elemento
  public saveChanges(valueToSave: any) {
    valueToSave.id = this.element.id;
    this.db.updateElement([valueToSave]);
  }

  //DOC: callback alla modifica dell'elemento 
  public onElementChanged(data: any) {
    this.logger.log("Elemento Modificato");
    if (!data.exists())
      this.errorManager.error("onElementChanged: data not valid");
    let d = data.val();
    //NOTE: per ogni campo controllo che sia stato modificato e quindi lo aggiorno nel modello
    //console.log(d); se elimino qualche campo non me ne accorgo dalla console
    Object.entries(d).forEach(
      ([key, value]) => {
        if (this.element[key] != value) {
          this.element[key] = value;
          console.log(key + " -> " + value);
        }
      }
    );
  }

  //DOC: le seguenti indicano se l'elemento è stato approvato, richiesto, è incompleto oppure rigettato
  @computedFrom('element.status')
  get experienceApproved() {
    return this.element.status == "approved";
  }
  @computedFrom('element.status')
  get experiencePending() {
    return this.element.status == "requested";
  }
  @computedFrom('element.status')
  get experienceIncomplete() {
    return this.element.status == "requested";
  }
  @computedFrom('element.status')
  get experienceRejected() {
    return this.element.status == "rejected";
  }

  //DOC: restituisce il css in base allo stato
  @computedFrom('element.status')
  get cssStatus() {
    switch (this.element.status) {
      case "requested": return "text-info";
      case "incomplete": return "text-warning";
      case "approved": return "text-success";
      case "rejected": return "text-danger";
      default: "";
    }
  }

  //DOC: indica se l'elemento ha una Confirm Request
  @computedFrom('element.idConfirm')
  get canRequestConfirm() {
    return this.element.idConfirm == null;
  }

  //DOC: inoltra nella pagina di Confirm per la richiesta della conferma dell'esperienza
  public requestConfirm() {
    //TODO: da cambiare con navigateTo
    this.router.navigate("confirms/new/" + this.element.id);
  }
}
