import { Router, RouterConfiguration, RedirectToRoute, NavigationInstruction, RouteConfig } from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';
import { Aurelia, autoinject, bindable, computedFrom } from 'aurelia-framework';
import { Route } from 'definitions';

import { ErrorManager } from 'helpers/errorManager';
import { Logger } from './helpers/logger';

import { Authentication } from './services/authentication';

import * as Firebase from 'firebase/app';

@autoinject
export class App {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = (new Logger()).setStyle(Logger.colors.black, Logger.colors.white).setDefaultPrefix("APP");

  @bindable loadingCompleted: boolean = false;

  //DOC: oggetto setup di Firebase
  private fireSetup = {
    apiKey: "AIzaSyCqADz-XQi9HtvK652WHhnjSroHUXkbYTM",
    authDomain: "profile-eae23.firebaseapp.com",
    databaseURL: "https://profile-eae23.firebaseio.com",
    projectId: "profile-eae23",
    storageBucket: "profile-eae23.appspot.com",
    messagingSenderId: "534682741470"
  };

  //DOC: oggetto statico con informazioni sull'applicazione //TODO: vedere se integrarlo/sostuirlo con le info in webpack
  public appVersionInfo = {
    stage: "alpha",
    releaseDate: new Date().toString(),
    author: "Jacopo Federici"
  }

  //DOC: oggetto Firebase che viene inizializzato nel costruttore
  public fire: Firebase.app.App;

  constructor(public router: Router) {
    this.logger.isDoc().info("App Version Info", this.appVersionInfo.stage, this.appVersionInfo.releaseDate.toLocaleString());
    //DOC: inizializzo App Firebase 
    this.fire = Firebase.initializeApp(this.fireSetup);
    this.logger.success("Firebase App", "inizializzata correttamente");
  }

  //----------------- ROUTING
  //DOC: configurazione del routing //NOTE: https://aurelia.io/docs/routing/configuration#basic-configuration
  configureRouter(config: RouterConfiguration, r: Router) {
    config.options.pushState = true;
    config.options.root = '/';
    config.title = "Profile" + " (" + this.appVersionInfo.stage + ")";
    config.mapUnknownRoutes(PLATFORM.moduleName('pages/_404Page'));
    config.map([
      {
        route: ['', 'index', 'index.html'], name: 'index', moduleId: PLATFORM.moduleName('pages/IndexPage'), title: 'Profile',
        visibility: 'never'
      },
      {
        route: 'features', name: 'features', moduleId: PLATFORM.moduleName('pages/IndexPage'), title: 'Features',
        nav: true, visibility: 'notlogged', navPosition: 'left'
      },
      {
        route: 'pricing', name: 'pricing', moduleId: PLATFORM.moduleName('pages/IndexPage'), title: 'Pricing',
        nav: true, visibility: 'notlogged', navPosition: 'left'
      },
      {
        route: 'companiesinstitutions', name: 'companiesinstitutions', moduleId: PLATFORM.moduleName('pages/IndexPage'), title: 'Companies and Institutions',
        nav: true, visibility: 'notlogged', navPosition: 'left'
      },
      {
        route: 'betatester', name: 'betatester', moduleId: PLATFORM.moduleName('pages/BetaTesterPage'), title: 'Become a βeta tester',
        nav: true, visibility: 'notlogged', navPosition: 'left'
      },
      {
        route: 'dashboard', name: 'dashboard', moduleId: PLATFORM.moduleName('pages/DashboardPage'), title: 'Dashboard',
        nav: true, visibility: 'logged', navPosition: 'right', auth: true
      },
      {
        route: 'user', name: 'user', moduleId: PLATFORM.moduleName('pages/UserPage'), title: 'Settings',
        nav: true, visibility: 'logged', navPosition: 'right'
      },
      {
        route: '/:id/', name: 'cv', href: '', moduleId: PLATFORM.moduleName('pages/CvPage'), title: 'Curriculum Vitae',
        visibility: 'never', auth: true
      },
      {
        route: 'login', name: 'login', moduleId: PLATFORM.moduleName('pages/LoginPage'), title: 'Sign in',
        nav: true, visibility: 'notlogged', navPosition: 'right', overflow: true
      },
      {
        route: 'logout', name: 'logout', moduleId: PLATFORM.moduleName('pages/LogoutPage'), title: 'Logout',
        nav: true, visibility: 'logged', navPosition: 'right', overflow: true, auth: true
      },
      {
        route: 'registration', name: 'registration', moduleId: PLATFORM.moduleName('pages/RegistrationPage'), title: 'Sign Up',
        nav: true, visibility: 'notlogged', navPosition: 'right', overflow: true
      },
      //DOC: modalità lettore
      {
        route: ['view/:id/'], name: 'view', href: '', moduleId: PLATFORM.moduleName('pages/ViewPage'), title: 'CV',
        nav: false, visibility: 'always', focusMode: true, onLoadingCompleted: () => { this.loadingCompleted = true; }
      }
    ] as Route[]);

    this.router = r;
    //TODO: redirect delle pagine con estensione (.php etc) a pagina home o pagina senza estensione
    config.addAuthorizeStep(AuthorizeStep);
  }

  //DOC: restituisce il css in base al focus mode
  @computedFrom('router.currentInstruction.config.route')
  get focusModeCss(): string {
    return (this.router.currentInstruction.config as Route).focusMode ? 'focus-mode' : '';
  }
  //DOC: restituisce il valore di focus mode
  @computedFrom('router.currentInstruction.config.route')
  get isFocusMode(): boolean {
    return (this.router.currentInstruction.config as Route).focusMode || false;
  }
  //DOC: indica se la callback onLoadingCompleted è settata
  @computedFrom('router.currentInstruction.config.route')
  get hasOnLoadingCompletedCallback(): boolean {
    //NOTE: se undefined allora onLoadingCompleted non ha riferita alcuna funzione
    return (this.router.currentInstruction.config as Route).onLoadingCompleted !== undefined;
  }
}

@autoinject
class AuthorizeStep {
  constructor(private logger: Logger, private auth: Authentication) { }

  //DOC: metodo necessario richiamato dallo Step
  run(navigationInstruction: NavigationInstruction, next: any) {
    return new Promise((resolve, reject) => {
      this.auth.onAuthStateChanged(
        user => {
          var loginRequired = navigationInstruction.config.auth && navigationInstruction.config.auth == true;
          if (!user && loginRequired) {
            //se è richiesto il login ma non sono loggato
            this.logger.warning("AuthorizeStep", "Login required", "redirect to the homepage");
            //console.debug(next);
            return resolve(next.cancel(new RedirectToRoute('login')));
          }
          if (user) {
            //NOTE: se sono loggato
            //this.logger.info("AuthorizeStep", "User logged in");
            return resolve(next());
          }
          //NOTE: se il login non è richiesto 
          //this.logger.info("AuthorizeStep", "Login not required");
          return resolve(next());
        }, this);
    });
  }
}
