import * as a from 'markdown-it';
import * as MarkdownIt from 'markdown-it/dist/markdown-it';
import * as Emoji from 'markdown-it-emoji';
import * as BracketedSpans from 'markdown-it-bracketed-spans';
import * as Attrs from 'markdown-it-attrs';
import * as Container from 'markdown-it-container';

export class MarkdownValueConverter {

  constructor() {
    MarkdownValueConverter.md
      .use(Emoji)
      .use(Attrs)
      .use(BracketedSpans)
      .use(Container, 'col', {
        validate: function (params) {
          return params.trim().match(/^col$/);
        },
        render: function (tokens, idx) {
          var m = tokens[idx].info.trim().match(/^col\s+(.*)$/);
          if (tokens[idx].nesting === 1) {
            // opening tag
            return '<div class="col">'; //m[1] futuro sviluppo di proporzionamento delle colonne
          } else {
            // closing tag
            return '</div>\n';
          }
        },
        marker: ":"
      })
      .use(Container, 'row', {
        validate: function (params) {
          return params.trim().match(/^row$/);
        },
        render: function (tokens, idx) {
          var m = tokens[idx].info.trim().match(/^row\s+(.*)$/);
          if (tokens[idx].nesting === 1) {
            // opening tag
            return '<div class="row">'; //m[1] futuro sviluppo di proporzionare le colonne
          } else {
            // closing tag
            return '</div>\n';
          }
        },
        marker: "="
      });

    //MarkdownValueConverter.md.inline.ruler.push("markdown-it-tag", MarkdownValueConverter.V);
    //tslint:disable-next-line: no-string-literal no-any  --  We NEED to disable this TSLint rule here since this is how the MarkdownIt library requires rules to be accessed
    /*MarkdownValueConverter.md.renderer.rules["markdown-it-tag"] =
      (tokens: MarkdownIt.Token[], idx: number, _opts: any, _env: any, self: MarkdownIt.Renderer): string => {
        const token = tokens[idx];
        return '<span ' + self.renderAttrs(token) + '>' + MarkdownValueConverter.md.utils.escapeHtml(tokens[idx].content) + '</span>';
      };*/
  }

  // ottiene il testo in markdown e restituisce l'html
  toView(value: any) {
    if (!value) return;
    return MarkdownValueConverter.md.render(value);
  }

  // full options list (defaults)
  public static md: MarkdownIt = new MarkdownIt({
    html: false,        // Enable HTML tags in source
    xhtmlOut: false,        // Use '/' to close single tags (<br />).
    // This is only for full CommonMark compatibility.
    breaks: false,        // Convert '\n' in paragraphs into <br>
    langPrefix: 'language-',  // CSS language prefix for fenced blocks. Can be
    // useful for external highlighters.
    linkify: false,        // Autoconvert URL-like text to links

    // Enable some language-neutral replacement + quotes beautification
    typographer: false,

    // Double + single quotes replacement pairs, when typographer enabled,
    // and smartquotes on. Could be either a String or an Array.
    //
    // For example, you can use '«»„“' for Russian, '„“‚‘' for German,
    // and ['«\xA0', '\xA0»', '‹\xA0', '\xA0›'] for French (including nbsp).
    quotes: '“”‘’',

    // Highlighter function. Should return escaped HTML,
    // or '' if the source string is not changed and should be escaped externally.
    // If result starts with <pre... internal wrapper is skipped.
    highlight: function (/*str, lang*/) { return ''; }
  });

  //è la funzione custom per riconoscere un token specifico
  //per adesso per i tag si usa BracketedSpans e Attrs che sono due estensioni di markedown-it
  //ho modificato il css per far funzionare questo:
  //[nometag]{.tag .classecategoria} 
  //NOTE: DO NOT WORK   |tag:nometag|   [categoria]
  public static V = (state: MarkdownIt.State, silent: boolean): boolean => {
    const tag1: string = "#tag:";
    var p = state.pos;
    if (state.src.charAt(p) === "#")
      console.log("yeah");
    if (state.src.charAt(p) !== "|") return false;
    if (state.src.substr(p, tag1.length) !== tag1) return false;

    var endPos = state.src.indexOf("|", p + tag1.length);
    if (endPos == -1) {
      if (!silent) state.pending += tag1;
      p += tag1.length;
      return true;
    }
    var content = state.src.substr(p + tag1.length, endPos);
    if (!content.match(/\r\n|\r|\n/g)) {  //if the variable doesn't include a line break
      if (!silent) {
        let token = state.push("markdown-it-tag", "span", 0);
        token.attrs = [
          ["class", "badge badge-primary"],
          ["cat", "for now i'm empty"]
        ];
        token.content = content;
        token.markup = tag1;
      }
      state.pos = p + endPos + 1;
      return true;
    }

  };


}
