import { customAttribute, autoinject } from 'aurelia-framework';

//DOC: implementa un attributo HTML custom che aggiunge il tooltip all'oggetto agganciato
//DOC: per l'uso aggiungere attributo html: tooltip. per proprietà vedere https://getbootstrap.com/docs/4.0/components/tooltips/

import 'common/attributes/css/tooltip.css';

@customAttribute('tooltip')
@autoinject
export class BootstrapTooltipAttribute {

  constructor(private element: Element) { }

  bind() {
    $(this.element).tooltip();
  }

  unbind() {
    $(this.element).tooltip("dispose");
  }

}
