import { observable, computedFrom, bindable, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { Database } from 'services/database';
import { Logger } from 'helpers/logger';
import { ErrorManager } from 'helpers/errorManager';
import { Authentication } from 'services/authentication';

import { Log } from 'definitions';

@autoinject
export class LogoutPage {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  constructor(private router: Router, private auth: Authentication) { }

  bind() {
    window.scrollTo(0, 0);
    if (!this.auth.isLogged) {
      this.router.navigateToRoute('index');
    }
    else {
      console.log("logging out...");
      setTimeout(
        () => this.auth.logout().then(() => this.router.navigateToRoute('index')),
        1000);
    }
  }

}
