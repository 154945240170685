import { Logger } from 'helpers/logger';
import 'firebase/auth';

export class ErrorManager {
  private logger: Logger = new Logger();
  //DOC: stack degli errori generati
  private errorStack: any[];
  //DOC: metodi per ottenere rispettivamente l'ultimo e tutti gli errori
  public getLastError = () => { return this.error[this.error.length - 1]; };
  public getAllErrors = () => { return this.errorStack };

  constructor() {
    this.errorStack = [];
  }

  //public errorHandler(error: firebase.auth.Error): void;
  public error(error: any): void {
    //aggiungo l'errore allo stack
    this.errorStack.push(error);
    this.logger.error("I got this error:");
    this.logger.classicLog(error);
    alert(JSON.stringify(error, null, 4));
    //gestisco l'errore in funzione del tipo


    //let a:firebase.auth.Error;
    /*
    if (typeof error == "firebase.auth.Error") {
this.logger.error("C'è stato qualche errore");
        this.logger.classicLog(error);
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // errori https://firebase.google.com/docs/reference/js/firebase.auth.Auth?authuser=0#signInWithEmailAndPassword
    } else if (typeof error == "object") {

    }
    else*/ {
    }
  }

  public ConsoleUnavailable() {
    //throw new Error("window.console non ha una classe console definita quindi la classe Logger non opera");
  }


}
