import { observable, computedFrom, bindable, autoinject } from 'aurelia-framework';
import { Router, RouteConfig, NavigationInstruction } from 'aurelia-router';
import { ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { BootstrapFormRenderer } from 'helpers/validationFormRender';
import { Confirm, Element } from 'definitions';

import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';

import { Query, Database, Path, ChildPath, Action } from 'services/database';
import { Functions } from 'services/functions';
import { Authentication } from 'services/authentication';

import * as Utils from 'helpers/utils';

import "./css/new-confirm-page.css"
import { validateEmail } from 'helpers/utils';

@autoinject
export class NewConfirmPage {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  //DOC: id del curriculum ottenuto dal parametro url
  private idCv: string = "";

  //DOC: array di confirms che mostro nella pagina
  @bindable confirms: Confirm[];

  @bindable requests: Confirm[];

  //DOC: nuova confirm che si vuole aprire
  @bindable newConfirmRequest: Confirm;
  //DOC:
  @bindable textConfirmRequest: string = "";

  @bindable emailReviewer: string;
  @bindable userResults: any = null;
  @bindable error: any;

  //DOC: esperienza (Element) che si vuole far confermare
  @bindable experience: Element;

  //DOC: array di query firebase che si utilizzano nel modello
  public queries: Query[] = [];

  //DOC: oggetto controller della validazione di forms
  public validationController: ValidationController;

  constructor(private auth: Authentication, private db: Database, private funct: Functions, private router: Router,
    private controllerFactory: ValidationControllerFactory) {
    this.validationController = controllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  activate(params: any, routeConfig: RouteConfig, navigationInstruction: NavigationInstruction) {
    if (params.id === null || params.new === null) {
      this.router.navigateToRoute("confirms");
    }

    this.idCv = params.id;
    //controllo se l'id passato esiste ed in caso ottento l'esperienza
    this.db.query(Path.elements).orderByKey().equalTo(params.new).limitToFirst(1)
      .once(Action.onValue, (data: any) => {
        if (data.exists()) {
          this.experience = Utils.toArrayOfObjects(data.val()).map(v => v = v as Element)[0];
          //console.log(this.experience);
        }
        else {
          alert("Id of the Element not found");
          this.logger.error("Id of the Element not found");
          this.router.navigateToRoute("confirms");
        }
      })
  }

  bind() {
    window.scrollTo(0, 0);
  }

  unbind() {
    //DOC: disinscrivo tutti gli eventi
    this.queries.forEach(q => q.off());
  }

  //DOC: callback alla lettura delle confirms del database
  public onConfirmsReading(data: any) {
    //console.log("confirms: ", data.val());
    this.confirms = Utils.toArrayOfObjects(data.val()).map(v => v = v as Confirm);
  }

  //DOC: callback alla lettura delle requests del database
  public onRequestsReading(data: any) {
    //console.log("requests: ", data.val());
    this.requests = Utils.toArrayOfObjects(data.val()).map(v => v = v as Confirm);
  }

  //DOC: metodo per la creazione di una richiesta di Confirm
  public sendConfirmRequest() {
    console.log("salvo la richiesta di confirm");
    this.newConfirmRequest = {
      id: "",
      idCurriculum: this.idCv,
      idReviewer: "",
      idExperience: this.experience.id,
      history: []
    } as Confirm;
    //aggiungo il la prima voce della storia con l'apertura della richiesta di confirm
    (this.newConfirmRequest.history = []).push({ date: (new Date()).getTime(), text: this.textConfirmRequest || "", state: "requested" });

    //valido il form 
    this.validationController
      .validate()
      .then(r => {
        //FIXME: la validazione non funziona
        if (r.valid) {
          this.logger.log("Salvo la richiesta di confirm");
          return this.db.appendConfirm(this.newConfirmRequest)
            .then(_ => {
              alert("Confirm request published successfully!");
              return this.closeNewConfirmPage();
            });
        }
      });

  }

  //DOC: metodo per annullare il processo di creazione di una Confirm Request
  public closeNewConfirmPage() {
    this.router.navigateToRoute("confirms");
  }

  public getUserByEmail() {
    if (!validateEmail(this.emailReviewer)) return;
    this.funct.httpsCallable("getUserByEmail_onCall")
      ({ email: this.emailReviewer })
      .then((result) => {
        this.userResults = result.data;
        //console.log(this.userResults);
      })
      .catch((error) => {
        this.error = error;
        //console.log(this.error);
      });
  }
}



//DOC: regole di validazione della form per la creazione di una 
ValidationRules
  //.ensure((c: ConfirmsCvPage) => c.newReview.idReviewer).required()
  //.ensure((c: ConfirmsCvPage) => c.newReview.idExperience).required()
  //.ensure((c: ConfirmsCvPage) => c.newReview.history).minItems(1)
  .ensure('emailReviewer').required().minLength(3) //FIXME: la validazione non funziona
  .on(NewConfirmPage);

