import { Curriculum, Category, Element, Log } from 'definitions';

//dato un array lo ordina in base alla proprietà propertyName ed alla direction
export class SortElementValueConverter {
  //FIXME: emettere il segnale non è un granchè, anzi: da cambiare proprio!!
  signals = ['position-changed'];

  toView(array: [Log | Element | Category | Curriculum], direction: string = "asc", propertyName: string = "position") {
    //controllo array sia un array e che contiene oggetti (ad esempio il primo: [0]) che hanno la proprietà propertyName
    if (Array.isArray(array) && array.length > 0 && array[0].hasOwnProperty(propertyName))
      return array.sort((a, b) => (a[propertyName] - b[propertyName]) * (direction == "asc" ? 1 : -1));
  }

}
