import { autoinject, bindable, signalBindings, bindingMode, computedFrom, customElement } from 'aurelia-framework';
import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';

import './css/curriculum-navbar-component.css';

@autoinject
export class CurriculumNavbarComponent {
  //DOC: links della navbar
  @bindable links: string;
  //DOC: title del curriculum
  @bindable title: string;

  //DOC: indica se l'elemento è nello stato di modifica
  @bindable canEdit: boolean = true;

  //DOC: evento sollevato quando links cambia contenuto
  @bindable onLinksChanged: (links: { links: string }) => void;
  //DOC: evento sollevato quando title cambia contenuto
  @bindable onTitleChanged: (title: { title: string }) => void;
}
