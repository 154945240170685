import { observable, bindingMode, computedFrom, bindable, autoinject } from 'aurelia-framework';

import * as AColorPicker from 'a-color-picker';

import "./color-picker.css";

interface options {
  attachTo?: String | Object,
  showHSL?: Boolean,
  showRGB?: Boolean,
  showHEX?: Boolean,
  showAlpha?: Boolean,
  color?: string,
  palette?: [],
  paletteEditable?: Boolean,
  useAlphaInPalette?: String | Boolean
}

@autoinject
export class ColorPicker {
  //DOC: id univoco creato per recuperare l'oggetto
  @bindable colorPickerId: string;

  //DOC: colore iniziale del picker e che varia il valore infx della scelta dell'utente
  @bindable({ defaultBindingMode: bindingMode.twoWay }) color: string;

  //DOC: controlla la visibilità del picker controllata dal button
  @bindable pickerVisible: boolean = false;

  //DOC: classi di stile aggiuntivo che si applicano al nodo padre
  @bindable css: string = "";

  //DOC: opzioni per la configurazione del picker
  @bindable options: options = {
    showRGB: false,
    showHSL: false,
    showHEX: true
  };

  constructor(private element: Element) { }

  bind() {
    //creo un ID univoco necessario se ho più picker nella stessa pagina
    this.colorPickerId = "picker" + (Math.floor(Math.random() * 100000) + 1).toString();
    this.element.querySelector('.picker').classList.add(this.colorPickerId);
    //imposto il colore di default se non è specificato il colore iniziale
    if (!this.color || this.color == "")
      this.color = "#979797";
  }

  attached() {
    //creo il picker quando il DOM è stato creato
    this.options.color = this.color;
    let picker = (AColorPicker.from(`.${this.colorPickerId}`, this.options) as any);
    picker.on('change', (picker, color) => {
      //console.log(AColorPicker.parseColor(color, "hexcss4"));
      this.color = AColorPicker.parseColor(color, "hexcss4");
    });
  }
}
