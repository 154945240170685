import { observable, computedFrom, bindable, autoinject } from 'aurelia-framework';
import { NavigationInstruction, RouteConfig, Router } from 'aurelia-router';
import { BindingEngine, Disposable } from 'aurelia-binding';

import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';
import { CurriculumStyle, Category, Element as Elem, Functions, defaultCurriculumStyle } from 'definitions';
import { LocalResources, iconPack } from 'services/storage';
import { Database, Query, Path, Action } from 'services/database';
import { setToDefaultValues, cloneObject } from 'helpers/utils';

import "./css/style-page.css";

@autoinject
export class StylePage {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  //DOC: oggetto che contiene tutte le proprietà di stile
  @bindable cvStyle: CurriculumStyle = null;
  //DOC: array di pack di icone
  @bindable iconsPacks: iconPack[] = [];
  //DOC: font family temporaneo
  @bindable localFontFamily: string;
  //DOC: icon pack temporaneo
  @bindable localIconPack: iconPack;
  //DOC: funzione match per i radio per la scelta dell'icon pack
  iconsPackMatcher = (a: iconPack, b: iconPack) => a.name == b.name;

  //DOC: //TODO:
  @bindable categories: string[] = [];

  //DOC: array di query firebase che si utilizzano nel modello
  public queries: Query[] = [];

  //DOC: id del curriculum (ottenuto dall'url)
  private idCv: string;

  //DOC: array di sottoscrizioni degli observer
  private propObserverSubscription: Disposable[] = [];

  constructor(public element: Element, private db: Database, public router: Router, private be: BindingEngine) { }

  bind() {
    //associo i parametri alle funzioni per essere richiamate dal componente padre
    this.router.currentInstruction.config.reset = () => { this.resetDefault() };

    //this.categories = Functions.getTagCategories(({ tags: "[HTML5]{.tag .cat} [CSS3]{.tag .cat} [Bootstrap]{.tag .cat} [JavaScript]{.tag .cat}" }) as Elem);\

    this.queries = [this.db.query(Path.curricula + this.idCv + "/" + Path.curriculumStyle)];
    this.queries[0].on(Action.onValue, (data: any) => this.onCurriculumStyleRead.call(this, data.val()));
  }

  activate(params: any, routeConfig: RouteConfig, navigationInstruction: NavigationInstruction) {
    if (params.id === null) {
      return alert("id cv non presente");
    }
    this.idCv = params.id;
    this.changeFontFamily();
  }

  unbind() {
    //DOC: disinscrivo tutti gli eventi
    this.queries.forEach(q => q.off());

    //DOC: disinscrivo tutte gli observer su cvStyle
    this.propObserverSubscription.forEach(s => s.dispose());
  }


  //DOC: callback richiamata quando ottengo i dati dello stile del curriculum
  public onCurriculumStyleRead(data: any) {
    console.log("Dati dell'utente letti o aggiornati");
    //console.log(data);
    //imposto i valori di default all'ottenimento della prima lettura dello stile
    if (!this.cvStyle) {
      //cvStyle è il join di valori tra quelli di default e quelli ottenuti dal db
      this.cvStyle = { ...defaultCurriculumStyle, ...data };
      this.iconsPacks = LocalResources.icons;
      this.changeFontFamily();
      this.localIconPack = { name: defaultCurriculumStyle.iconsPack, icons: [] }

      //DOC: creo le sottoscrizioni agli observer su cvStyle (che ha sicuramente tutti i campi settati)
      console.log("creo sottoscrizioni agli observer su cvStyle:", this.cvStyle);
      for (let o in this.cvStyle) {
        this.propObserverSubscription.push(this.be.propertyObserver(this.cvStyle, o).subscribe((_new, _old) => {
          //console.log(o, ":", _old, "-->", _new)
          let obj = {}; obj[o] = _new;
          this.db.updateCurriculumStyle(this.idCv, obj);
        }))
      }
    }
    setToDefaultValues(this.cvStyle, data);
  }

  localIconPackChanged(_new, _old) {
    //console.log(_old, "-->", _new);
    this.db.updateCurriculumStyle(this.idCv, { iconsPack: this.localIconPack });
  }

  //DOC: imposta il font secondo selezione alla pagina corrente
  changeFontFamily() {
    if (!document) return;
    let linktag = document.querySelector('#googlefont') as HTMLLinkElement;
    let fontFamily = this.cvStyle ? this.cvStyle.textFamily : defaultCurriculumStyle.textFamily; //NOTE: deve essere del formato: 'Permanent Marker', cursive;
    let fontLink = fontFamily.split(',')[0].replace(/'/g, '').replace(/ /g, '+');
    linktag.href = `https://fonts.googleapis.com/css?family=${fontLink}`;
    //console.log(linktag.href);
    this.localFontFamily = fontFamily;
  }

  //DOC: spostamento della finestra all'elemento passato TODO: non usato!! controllare
  scrollToSection(section: string) {
    if (this.element)
      this.element.querySelector(section).scrollIntoView({ block: 'center', behavior: 'smooth' });
  }

  //DOC: resetta i valori di tutto lo stile ai valori di default
  public resetDefault() {
    if (confirm("You are going to reset the default values, are you sure?")) {
      this.cvStyle = defaultCurriculumStyle;
      this.db.updateCurriculumStyle(this.idCv, this.cvStyle);
    }
  }

  //DOC: eventi hovering/unhovering mouse sul layout
  onHovering(e: Event) {
    e.stopPropagation();
    (e.target as Element).classList.add("border-dark");
  }
  onUnHovering(e: Event) {
    e.stopPropagation();
    (e.target as Element).classList.remove("border-dark");
  }
}
