//DOC: classe per la gestione dei messaggi in console
//NOTE: specs: https://developer.mozilla.org/en-US/docs/Web/API/console#Outputting_text_to_the_console
export class Logger {
  //DOC: indica lo stato di attivazione
  private static enabled: boolean = true;
  //DOC: attiva il logger
  public static activate = (): void => { Logger.enabled = true };
  //DOC: disattiva il logger e pulisce la console
  public static deactivate = (): void => { Logger.enabled = false; console.clear(); };
  //DOC: restituisce lo stato del Logger
  public static isEnabled = (): boolean => Logger.enabled;

  //DOC: restituisce la console di window se c'è, altrimenti restituisce errore
  private get console(): any {
    return (window.console && Logger.enabled) ? console : false;
  }

  //DOC: colori base usati, accessibili anche dall'esterno
  public static colors = {
    black: "#000", white: "#fff", blue: "#007bff", lightblue: "#75b4f9", indigo: "#6610f2",
    purple: "#6f42c1", pink: "#e83e8c", red: "#dc3545", orange: "#fd7e14", yellow: "#ffc107",
    green: "#28a745", teal: "#20c997", cyan: "#17a2b8", gray: "#6c757d", graydark: "#343a40",
    primary: "#007bff", secondary: "#6c757d", success: "#28a745", info: "#17a2b8", warning: "#ffc107",
    error: "#dc3545", light: "#f8f9fa", dark: "#343a40"
  }
  //DOC: definisce lo stile del prefisso (background, testo)
  private prefixStyle: string[] = [Logger.colors.lightblue, Logger.colors.white];

  //DOC: imposta lo stile del prefisso (background, testo)
  public setStyle(s1: any, s2: any) {
    this.prefixStyle = [s1, s2];
    return this;
  }
  //DOC: codice freccia
  public arrow: string = '\u21d2';

  //DOC: flag stato stampa etichetta
  private _docLog = false;
  private _fixmeLog = false;
  private _todoLog = false;

  //DOC: metodi per usare etichette
  public isDoc = (): Logger => { this._docLog = true; return this; };
  public isFixme = (): Logger => { this._fixmeLog = true; return this; };
  public isTodo = (): Logger => { this._todoLog = true; return this; };

  //DOC: testo prefisso di default
  private defaultPrefix = "";
  //DOC: imposta il prefisso di default
  public setDefaultPrefix = (s1: string): Logger => { this.defaultPrefix = s1; return this; }

  constructor() {
    //DOC: assegno la funzione callingDebugMode da chiamare alla pressione dei tasti ctrl+d
    document.onkeydown = this.callingDebugMode;
  }

  //DOC: evento alla pressione dei tasti ctrl + / (ctrlKey + 191)
  private callingDebugMode = (e: any) => {
    var evtobj = window.event ? event : e
    if (evtobj.keyCode == 191 && evtobj.ctrlKey) {
      if (!Logger.enabled) {
        Logger.activate();
        alert("Modalita' debug attivata, apri la console per vedere i messaggi");
      }
      else {
        Logger.deactivate();
        alert("Modalita' debug disattivata");
      }
    }
  }

  //DOC: metodo per la stampa in console, per ogni elemento in args c'è uno stile associato in styles
  private _log(args: any[], styles: string[]) {
    if (this._docLog) {
      args.unshift("DOC:");
      styles.unshift(`background-color: ${Logger.colors.blue}; color: ${Logger.colors.white}; font-weight: bold;`);
      this._docLog = false;
    }
    if (this._fixmeLog) {
      args.unshift("FIXME:");
      styles.unshift(`background-color: ${Logger.colors.pink}; color: ${Logger.colors.white}; font-weight: bold;`);
      this._fixmeLog = false;
    }
    if (this._todoLog) {
      args.unshift("TODO:");
      styles.unshift(`background-color: ${Logger.colors.orange}; color: ${Logger.colors.white}; font-weight: bold;`);
      this._todoLog = false;
    }
    if (this.defaultPrefix != "") {
      if (args.length == 2) {
        let t = args[0];
        args[0] = args[1];
        args[1] = t;
      }
      args[0] = this.defaultPrefix + " " + args[0];
    }

    //NOTE: se gli array non hanno lo stesso numero di elementi aggiungo stringe vuote al più corto
    let diff = args.length - styles.length;
    if (diff > 0) styles.push(...(new Array(diff).fill(styles[styles.length - 1])));
    else if (diff < 0) styles = styles.slice(0, diff);
    //NOTE: se l'ultimo elemento in args è la freccia allora rimuovo l'elemento ed il suo corrispondente in styles
    if (args[args.length - 1] == this.arrow) {
      args.splice(args.length - 1, 1);
      styles.splice(styles.length - 1, 1);
    }
    this.console.log(`%c ${args.join(" %c ")} `, ...styles);
  }

  //DOC: metodi di log, info, warning, error, documentazione, correzione
  log(prefix: string, ...args: any[]) {
    this._log(
      [prefix, this.arrow, ...args],
      [`background-color: ${this.prefixStyle[0]}; color:${this.prefixStyle[1]};`,
      `background-color: ${Logger.colors.white}; color:${Logger.colors.black};`,
      `background-color:${Logger.colors.white}; color:${Logger.colors.black};`]);
    return this;
  }
  info(prefix: string, ...args: any[]) {
    this._log(
      [prefix, this.arrow, ...args],
      [`background-color: ${this.prefixStyle[0]}; color:${this.prefixStyle[1]};`,
      `background-color: ${Logger.colors.white}; color:${Logger.colors.black};`,
      `background-color:${Logger.colors.lightblue}; color:${Logger.colors.white};`]);
    return this;
  }
  warning(prefix: string, ...args: any[]) {
    this._log(
      [prefix, this.arrow, ...args],
      [`background-color: ${this.prefixStyle[0]}; color:${this.prefixStyle[1]};`,
      `background-color: ${Logger.colors.white};color:${Logger.colors.black};`,
      `background-color:${Logger.colors.warning};color:${Logger.colors.white};`]);
  }
  error(prefix: string, ...args: any[]) {
    this._log(
      [prefix, this.arrow, ...args],
      [`background-color: ${this.prefixStyle[0]}; color:${this.prefixStyle[1]};`,
      `background-color: ${Logger.colors.white} ;color:${Logger.colors.black};`,
      `background-color:${Logger.colors.error};color:${Logger.colors.white};`]);
  }
  success(prefix: string, ...args: any[]) {
    this._log(
      [prefix, this.arrow, ...args],
      [`background-color: ${this.prefixStyle[0]}; color:${this.prefixStyle[1]};`,
      `background-color: ${Logger.colors.white} ;color:${Logger.colors.black};`,
      `background-color:${Logger.colors.green};color:${Logger.colors.white};`]);
  }

  //DOC: wrapping di log() classico
  classicLog(...args: any[]) {
    this.console.log(...args);
  }
  //DOC: wapping di warn(): crea un gruppo collassabile con lo stack delle chiamate
  warn(...args: any[]) {
    this.console.warn(...args);
  }
  //DOC: wrapping di table(): stampa array o oggetti in forma tabellare
  table(data: {} | []) {
    this.console.table(data);
  }

  //DOC: stampa un elenco di oggetti args con prefisso prefix in un gruppo collassabile
  grouped(prefix: string, ...args: any[]) {
    this.console.groupCollapsed(prefix);
    args.forEach(arg => this.log(arg));
    this.console.groupEnd();
  }

  //DOC: apre un gruppo collassabile
  openGroup(prefix: string) {
    this.console.group(prefix);
  }

  //DOC: chiude l'ultimo gruppo collassabile aperto
  closeGroup() {
    this.console.groupEnd();
  }

  //DOC: pulisce la console (non ha effetto su Google Chrome se è spuntata l'opzione 'preserve log')
  clear() {
    this.console.clear();
  }
}
