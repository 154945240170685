import { customAttribute, autoinject } from 'aurelia-framework';

//DOC: implementa un attributo HTML custom che abilita il popover all'oggetto agganciato
//DOC: per l'uso aggiungere attributo html: popover. per proprietà vedere https://getbootstrap.com/docs/4.2/components/popovers/

import 'common/attributes/css/popover.css';

@customAttribute('popover')
@autoinject
export class BootstrapPopoverAttribute {
  constructor(private element: Element) { }

  bind() {
    $(this.element).popover({
      container: 'body',
      //trigger: 'focus'
    });
  }

  unbind() {
    $(this.element).popover('dispose');
  }

}
