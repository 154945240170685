import { autoinject, inject } from 'aurelia-framework';
import { Container } from 'aurelia-dependency-injection';
import { HttpClient, HttpClientConfiguration } from 'aurelia-fetch-client';

import { Authentication } from 'services/authentication';
import { Curriculum, Category, Element, User, Log, Functions, Confirm, ConfirmHistoryItem } from 'definitions';

import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';

import * as Firebase from 'firebase/app';
import 'firebase/database';

//NOTE: gli oggetti in Javascript sono passati per riferimento,
//in ogni funzione che accetta un oggetto ne creo una copia locale e lavoro su quella

//DOC: prefisso di tutte le risorse nel database Firebase
export const DatabasePrefix: string = "/alpha/";

//DOC: percorsi delle risorse base del database
export enum Path {
  users = "users/",
  curricula = "curricula/",
  categories = "categories/",
  elements = "elements/",
  logs = "logs/",
  confirms = "confirms/",
  curriculumStyle = "style/" //sub property of curriculum
}

//DOC: eventi dei listener di Firebase (è un doppione di Firebase.databse.EventType ma almeno non ho stringhe in giro)
export enum Action {
  onValue = "value",
  onAdded = "child_added",
  onRemoved = "child_removed",
  onChanged = "child_changed"
}

//DOC: indici di ricerca usati nelle query
export enum ChildPath {
  idUser = "uid",
  idCurriculum = "idCurriculum",
  idCategory = "idCategory",
  idConfirm = "idConfirm",
  idReviewer = "idReviewer",
  historyConfirm = "history"
  //.....etc
}

//DOC: introdotto nuovo tipo per semplificare la notazione
export type Query = Firebase.database.Query;

//DOC: classe wrapper di firebase per la manipolazione del database (Firebase)
@autoinject
export class Database {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = (new Logger()).setStyle(Logger.colors.indigo, Logger.colors.white).setDefaultPrefix("DB");

  //DOC: oggetto che rappresenta il database Firebase remoto
  private db: Firebase.database.Database;

  //DOC: riferimento all'Authentication di Firebase
  private _auth: Authentication = null;
  //DOC: inizializza o restituisce il riferimento all'Authentication instanziato
  private get auth(): Authentication {
    if (!this._auth) this._auth = this.container.get(Authentication);
    return this._auth;
  };

  constructor(private container: Container) {
    //DOC: inizializzazione del Database
    this.db = Firebase.database();
    this.logger.success("Firebase Database", "inizializzato correttamente");
  }

  //DOC: setta il path della Reference e la restituisce per costruire la Query (è il primo step della query)
  public query(path: Path | string) {
    return this.db.ref(DatabasePrefix + path);
  }

  //------------------------------------------------------------------------------------------------------
  //NOTE: elenco funzioni inserimento/cancellazione/modifica elementi remoti del db
  //NOTE: funzioni oggetto Review

  //DOC: aggiunge un log al database
  public appendConfirm(_rev: Confirm): Promise<any> {
    var newRevKey = this.db.ref().child(DatabasePrefix + Path.confirms).push().key;
    var updates = {};
    //NOTE: appendo la nuova review
    updates[DatabasePrefix + Path.confirms + "/" + newRevKey] = _rev;
    return new Promise<any>((ok, ko) =>
      this.db.ref().update(updates)
        .then(() => ok(newRevKey))
        .catch(e => { this.errorManager.error(e); ko(); }));
  }

  //DOC: aggiunge un log al database
  public appendHistoryConfirm(idRev: string, itemIndex: number, item: ConfirmHistoryItem): Promise<any> {
    var updates = {};
    //NOTE: appendo la nuova item della history della review
    updates[DatabasePrefix + Path.confirms + idRev + "/" + ChildPath.historyConfirm + "/" + itemIndex + "/"] = item;
    return new Promise<any>((ok, ko) =>
      this.db.ref().update(updates)
        .then(() => ok(item))
        .catch(e => { this.errorManager.error(e); ko(); }));
  }

  //------------------------------------------------------------------------------------------------------
  //NOTE: funzioni oggetto Log

  //DOC: aggiunge un log al database
  public appendLog(_log: Log) {
    var newLogKey = this.db.ref().child(DatabasePrefix + Path.logs).push().key;
    var updates = {};
    //NOTE: appendo il nuovo log
    updates[DatabasePrefix + Path.logs + "/" + newLogKey] = _log;
    this.db.ref().update(updates)
      .then()
      .catch(
        e => this.errorManager.error(e)
      );
  }

  //------------------------------------------------------------------------------------------------------
  //NOTE: funzioni oggetto User

  //DOC: aggiunge nel database l'utente passato
  public appendUser(_user: User) {
    //NOTE: l'utente ha come chiave il suo UID che è lo stesso di AUTH
    var uid = this.auth.getCurrentUser().uid;
    return this.db.ref(DatabasePrefix + Path.users + uid).once('value').then(
      (user) => {
        if (user.exists()) {
          this.logger.error("L'utente è già nel database!");
          this.errorManager.error("user already exists!");
        }
        else {
          this.db.ref(DatabasePrefix + Path.users + uid)
            .set(Functions.toDatabase(_user))
            .catch(e => this.errorManager.error(e));
        }
      }
    );
  }

  //DOC: aggiorna nel database l'utente passato
  public updateUser(uid: string, values: any) {
    this.db.ref().child(DatabasePrefix + Path.users + uid)
      .update(values)
      .then()
      .catch(
        e => this.errorManager.error(e)
      );
  };

  //TODO: rimuove dal database l'utente avente l'id passato
  public deleteUser(uid: string) {
    this.logger.isTodo().log("Il metodo per eliminare l'utente `${idUser}`non è stato implementato");
  }

  //------------------------------------------------------------------------------------------------------
  //NOTE: funzioni oggetto CV

  //DOC: aggiunge nel database il curriculum passato
  public appendCurriculum(_curriculum: Curriculum) {
    var curriculum: Curriculum = _curriculum;
    var newCurriculumKey = this.db.ref().child(DatabasePrefix + Path.curricula).push().key;
    var updates = {};
    //NOTE: appendo il curriculum nuovo ed aggiungo il suo id alla lista degli id dell'utente a cui appartiene
    updates[DatabasePrefix + Path.curricula + newCurriculumKey] = Functions.toDatabase(curriculum)
    updates[DatabasePrefix + Path.users + curriculum.uid + "/idCurricula/" + newCurriculumKey] = true;
    //console.log(updates);
    this.db.ref().update(updates)
      .then(_ =>
        this.appendLog({
          dateTime: (new Date()).getTime(),
          uid: this.auth.getCurrentUser().uid,
          subject: "DB.createCurriculum",
          operation: `Created new curriculum (${_curriculum.title})`,
          security: false
        } as Log))
      .catch(
        e => this.errorManager.error(e)
      );
  };

  //DOC: aggiorna nel database il curriculum passato
  public updateCurriculum(id: string, values: any) {
    this.db.ref().child(DatabasePrefix + Path.curricula + id)
      .update(values)
      .then()
      .catch(
        e => this.errorManager.error(e)
      );
  };
  
  //DOC: aggiorna nel database il curriculum passato
  public updateCurriculumStyle(id: string, values: any) {
    this.db.ref().child(DatabasePrefix + Path.curricula + id + "/" + Path.curriculumStyle)
      .update(values)
      .then()
      .catch(
        e => this.errorManager.error(e)
      );
  };

  //DOC: elimina dal database un curriculum
  public deleteCurriculum(title: string, idCurriculum: string, uid: string) {
    var updates = {};
    //NOTE: rimuovo a) il curriculum dalla lista dei curricula b) l'idCurriculum dall'utente idUser che contiene il curriculum
    //NOTE: c) tutte le categorie che il curriculum contiene d) tutti gli elementi che sono contenuti dalle categorie del curriculum 
    updates[DatabasePrefix + Path.curricula + idCurriculum] = null; //NOTE: a)
    updates[DatabasePrefix + Path.users + uid + "/idCurricula/" + idCurriculum] = null; //NOTE: b)
    this.db.ref().child(DatabasePrefix + Path.categories).orderByChild("idCurriculum").equalTo(idCurriculum) //NOTE: c)
      .once('value').then(
        cats => {
          cats.forEach(
            cat => this.deleteCategory({ id: cat.key, idCurriculum: idCurriculum }));
        })
      .then(() =>
        this.db.ref().update(updates)
          .then(_ => this.appendLog({
            dateTime: (new Date()).getTime(),
            uid: this.auth.getCurrentUser().uid,
            subject: "DB.deleteCurriculum",
            operation: `Deleted curriculum (${title})`,
            security: false
          } as Log))
          .catch(
            e => this.errorManager.error(e)
          ))
  };

  //------------------------------------------------------------------------------------------------------
  //NOTE: funzioni oggetto Category

  //DOC: aggiunge al database la categoria passata
  public appendCategory(_category: Category) {
    var category = _category as Category;
    var newCategoryKey = this.db.ref().child(DatabasePrefix + Path.categories).push().key;
    var updates = {};
    //NOTE: appendo la categoria nuova ed aggiungo il suo id alla lista degli id del curriculum a cui appartiene
    updates[DatabasePrefix + Path.categories + newCategoryKey] = Functions.toDatabase(category);
    updates[DatabasePrefix + Path.curricula + category.idCurriculum + "/idCategories/" + newCategoryKey] = true;
    //console.log(updates);
    this.db.ref().update(updates)
      .then()
      .catch(
        e => this.errorManager.error(e)
      );
  };
  //DOC: aggiorna nel database tutte le categorie passate
  public updateCategory(cats: Category[]) {
    if (cats.length == 0) return;
    var aggiornamenti = {};
    //per ogni categoria, scorro le proprietà e creo l'array di aggiornamento
    cats.forEach(c => Object.keys(Functions.toDatabase(c)).forEach(p =>
      aggiornamenti[DatabasePrefix + Path.categories + c.id + '/' + p] = c[p]
    ));
    return this.db.ref().update(aggiornamenti)
      .then()
      .catch(
        e => this.errorManager.error(e)
      );
  };
  //DOC: elimina dal database la categoria passata
  public deleteCategory(category: { id: string, idCurriculum: string }) {
    var updates = {};
    //NOTE: rimuovo a) la categoria dalla lista delle categorie b) l'id dal curriculum idCurriculum che lo contiene
    //NOTE: c) tutti gli elementi che la categoria contiene
    updates[DatabasePrefix + Path.categories + category.id] = null; //NOTE: a)
    updates[DatabasePrefix + Path.curricula + category.idCurriculum + "/idCategories/" + category.id] = null; //NOTE: b)
    this.db.ref().child(DatabasePrefix + Path.elements).orderByChild("idCategory").equalTo(category.id) //NOTE: c)
      .once('value')
      .then(snapshot => {
        snapshot.forEach(child => updates[DatabasePrefix + Path.elements + child.key] = null);
        //this.logger.log(updates);
      })
      .then(() =>
        this.db.ref().update(updates)
          .then()
          .catch(
            e => this.errorManager.error(e)
          ));
  };

  //------------------------------------------------------------------------------------------------------
  //NOTE: funzioni oggetto Element

  //DOC: aggiunge al database l'elemento passato
  public appendElement(_element: Element) {
    //console.log(_element);
    var element = _element as Element;
    var newElementKey = this.db.ref().child(DatabasePrefix + Path.elements).push().key;
    var updates = {};
    //NOTE: appendo l'elemento nuovo ed aggiungo il suo id alla lista degli id della categoria a cui appartiene
    updates[DatabasePrefix + Path.elements + newElementKey] = Functions.toDatabase(element);
    updates[DatabasePrefix + Path.categories + element.idCategory + "/idElements/" + newElementKey] = true;
    //console.log(updates);
    this.db.ref().update(updates)
      .then()
      .catch(
        e => this.errorManager.error(e)
      );
  }
  //DOC: aggiorna nel database l'elemento passato
  public updateElement(eles: Element[]) {
    if (eles.length == 0) return;
    var aggiornamenti = {};
    eles.forEach(e => Object.keys(Functions.toDatabase(e)).forEach(p =>
      aggiornamenti[DatabasePrefix + Path.elements + e.id + '/' + p] = e[p]
    ));
    return this.db.ref().update(aggiornamenti)
      .then()
      .catch(
        e => this.errorManager.error(e)
      );
  };
  //DOC: elimina dal database l'elemento passato
  public deleteElement(element: { id: string, idCategory: string }) {
    //this.logger.log(element);
    var updates = {};
    //NOTE: rimuovo a) l'elemento dalla lista degli elementi e b) l'id dalla categoria idCategory che lo contiene
    updates[DatabasePrefix + Path.elements + element.id] = null; //NOTE: a)
    updates[DatabasePrefix + Path.categories + element.idCategory + "/idElements/" + element.id] = null; //NOTE: b)
    this.db.ref().update(updates)
      .then()
      .catch(
        e => this.errorManager.error(e)
      );
  };
}
