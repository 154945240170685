import { observable, computedFrom, bindable, autoinject } from 'aurelia-framework';
import { Router, RouteConfig, RouterConfiguration, NavigationInstruction } from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';

import { Curriculum, User } from 'definitions';
import { Database, Path, ChildPath, Action, Query } from 'services/database';
import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';
import * as Utils from 'helpers/utils';

import './css/cv-page.css';

@autoinject
export class CvPage {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = (new Logger()).setStyle(Logger.colors.orange, Logger.colors.white).setDefaultPrefix("EditorCVPage");

  //DOC: indica se l'elemento è nello stato di EDIT, se false è in stato di VIEW
  @bindable canEdit: boolean = true;
  //DOC: indica se in stato di EDIT si sta modificando
  @bindable isEditing: boolean = false;

  constructor(private db: Database, private router: Router) { }

  bind() {
    window.scrollTo(0, 0);
  }

  configureRouter(config: RouterConfiguration, router: Router) {
    config.map([
      {
        route: ['', 'view'], name: 'view', moduleId: PLATFORM.moduleName('pages/CvSubpages/ViewCvPage'),
        nav: true, title: 'Preview', icon: 'fa-eye'
      },
      {
        route: 'edit', name: 'edit', moduleId: PLATFORM.moduleName('pages/CvSubpages/EditCvPage'),
        nav: true, title: 'Edit', icon: 'fa-pen-nib'
      },
      {
        route: 'setting', name: 'setting', moduleId: PLATFORM.moduleName('pages/CvSubpages/SettingCvPage'),
        nav: true, title: 'Setting', icon: 'fa-cog'
      },
      {
        route: 'protection', name: 'protection', moduleId: PLATFORM.moduleName('pages/CvSubpages/ProtectionCvPage'),
        nav: true, title: 'Protection', icon: 'fa-shield-alt'
      },
      {
        route: 'confirms', name: 'confirms', moduleId: PLATFORM.moduleName('pages/CvSubpages/ConfirmsCvPage'),
        nav: true, title: 'Confirms', icon: 'fa-check-double'
      },
      /*{
        route: 'confirms/new/:new', name: 'confirms/new', moduleId: PLATFORM.moduleName('pages/CvSubpages/ConfirmsCvSubpages/NewConfirmPage'),
        nav: false, title: 'New Confirm'
      },*/
      {
        route: 'insight', name: 'insight', moduleId: PLATFORM.moduleName('pages/CvSubpages/InsightCvPage'),
        nav: true, title: 'Insight', icon: 'fa-chart-bar'
      }
      //  Insight More
    ]);
    this.router = router;
  }

}
