import { observable, computedFrom, bindable, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { Database } from 'services/database';
import { Authentication } from 'services/authentication';
import { User } from 'definitions';

import { Logger } from 'helpers/logger';
import { ErrorManager } from 'helpers/errorManager';

@autoinject
export class RegistrationPage {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  @bindable name: string = "";
  @bindable lastname: string = "";
  @bindable email: string = "";
  @bindable password: string = "";

  constructor(private router: Router, private auth: Authentication, private database: Database) { }

  bind() {
    window.scrollTo(0, 0);
    if (this.auth.isLogged)
      this.router.navigateToRoute('dashboard');
  }

  //DOC: metodo per la registrazione dell'utente
  public registerUser() {
    this.logger.info("Registration page", this.name, this.lastname, this.email, this.password);
    this.auth.registration(this.email, this.password)
      .then(_ => {
        //NOTE: se tutto ok invio email di verifica
        this.auth.sendEmailVerification();
        //NOTE: e aggiungo l'utente al database
        let t = new Date();
        this.database.appendUser(
          {
            id: this.auth.getCurrentUser().uid,
            name: this.name,
            lastname: this.lastname,
            lastLogin: t.getTime(),
            dor: t.getTime(),
            enabled: false
          } as User);
      })
      .then(() => this.router.navigateToRoute('dashboard'))
      .catch();
  }

}
