import { autoinject, computedFrom, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Database, Path, ChildPath, Action, Query } from 'services/database';
import { Confirm, ConfirmHistoryItem } from 'definitions';

import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';

import './css/confirm-requested-component.css';

//DOC: modello della confirm di cui l'utente (applicant) ha chiesto la conferma
@autoinject
export class ConfirmRequestedComponent {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  //DOC: la confirm che mostro
  @bindable confirm: Confirm;

  //DOC: indica se si stanno caricando i dati
  @bindable isLoadingData: boolean = true;

  //DOC: array di query firebase che si utilizzano nel modello
  public queries: Query[] = [];

  constructor(private db: Database, private router: Router) { }

  bind() {
    if (!this.confirm.history || this.confirm.history.length == 0)
      throw new Error("the history array for the confirm " + this.confirm.id + " is empty");
    //DOC: ordino l'array history in base alla data
    this.confirm.history.sort((i1, i2) => i1.date - i2.date);

    //TODO: caricare le informazioni del reviewer (nome, email, etc..) con una call ad una function
  }

  //DOC: mostra l'esperienza riferita
  public viewExperience(experienceId: string) {
    this.router.navigateToRoute("edit", { goto: experienceId });
  }

  //DOC: restituiscelo lo stato della confirm
  @computedFrom('confirm.history.length')
  get lastStatus() {
    return this.confirm.history[this.confirm.history.length - 1].state;
  }
  //DOC: restituscie la data dell'ultimo azione riguardante la confirm
  @computedFrom('confirm.history.length')
  get lastEdit() {
    return this.confirm.history[this.confirm.history.length - 1].date;
  }

  //DOC: restituisce il css in base allo stato della confirm
  @computedFrom('confirm.history.length')
  get statusCss() {
    switch (this.lastStatus) {
      case "requested": return "secondary";
      case "incomplete": return "warning";
      case "approved": return "success";
      case "rejected": return "danger";
      default: return "";
    }
  }

}
