import { bindable, autoinject } from "aurelia-framework";
import { Router } from 'aurelia-router';

import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';

import './css/dashboard-curriculum-component.css'

@autoinject
export class DashboardCurriculumComponent {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  //DOC: oggetto curriculum da visualizzare
  @bindable curriculumData: any;

  //DOC: evento sollevato alla apertura del cv
  @bindable public onOpening: (id: any) => void;
  //DOC: evento sollevato alla cancellazione del cv
  @bindable public onDelete: (cv: any) => void;

  constructor() { }

  public deleteCurriculum(o: any, event: any) {
    event.stopImmediatePropagation();
    if (!window.confirm('Do you really want to delete the curriculum?')) return;
    this.onDelete(o);
  }

}
