// we want font-awesome to load as soon as possible to show the fa-spinner
import "@fortawesome/fontawesome-free/css/all.css"
import '../static/styles.css';
import 'bootstrap/dist/css/bootstrap.css';

import { PLATFORM } from 'aurelia-pal';
import { Aurelia } from 'aurelia-framework';

// comment out if you don't want a Promise polyfill (remove also from webpack.config.js)
import * as Bluebird from 'bluebird';

Bluebird.config({ warnings: false });

export async function configure(aurelia: Aurelia) {
  aurelia.use
    .standardConfiguration()
    .plugin(PLATFORM.moduleName('aurelia-validation'))
    .developmentLogging();
  //.plugin(PLATFORM.moduleName('aurelia-animator-css'));
  // if the css animator is enabled, add swap-order="after" to all router-view elements

  // Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
  // aurelia.use.plugin('aurelia-html-import-template-loader')

  await aurelia.start();
  aurelia.setRoot(PLATFORM.moduleName('app'));
}