import * as Moment from 'moment';

export class DateFormatValueConverter {
  toView(value: any, format: string) {
    //console.log("tovalue: ", value);
    return Moment(value).format(format);
  }
  fromView(value: any) {
    //console.log("fromvalue: ", value);
    return Moment(value).format('x');
  }
}
