import { PLATFORM } from 'aurelia-pal';
import { bindable, autoinject } from 'aurelia-framework';
import { Router, RouterConfiguration } from 'aurelia-router';

import { Logger } from 'helpers/logger';
import { ErrorManager } from 'helpers/errorManager';
import { Authentication } from 'services/authentication';

@autoinject
export class UserPage {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  constructor(private router: Router) { }

  configureRouter(config: RouterConfiguration, router: Router) {
    config.map([
      { route: ['', 'profile'], name: 'user/profile', moduleId: PLATFORM.moduleName('pages/UserSubpages/ProfilePage'), nav: true, title: 'Profile' },
      { route: 'account', name: 'user/account', moduleId: PLATFORM.moduleName('pages/UserSubpages/AccountPage'), nav: true, title: 'Account' },
      { route: 'email', name: 'user/email', moduleId: PLATFORM.moduleName('pages/UserSubpages/EmailPage'), nav: true, title: 'Email' },
      { route: 'notifications', name: 'user/notifications', moduleId: PLATFORM.moduleName('pages/UserSubpages/NotificationsPage'), nav: true, title: 'Notifications' },
      { route: 'security', name: 'user/security', moduleId: PLATFORM.moduleName('pages/UserSubpages/SecurityPage'), nav: true, title: 'Security' }
    ]);
    this.router = router;
  }

  bind() {
    window.scrollTo(0, 0);
  }
}
