//DOC: trasforma un oggetto di oggetti (di uguale struttura) in un array di oggetti
// inserendo il campo id con il valore key ottenuto. E' utile per gestire i dati restituiti da Firebase
export function toArrayOfObjects(obj: any): any[] {
  var arr = [];
  for (var k in obj) {
    var o = obj[k];
    o["id"] = k;
    arr.push(o);
  }
  return arr;
}

//DOC: valuta il colore passato e restituisce true se è scuro e false se è chiaro
export function getContrastYIQ(hexcolor: string) {
  const [r, g, b] = [0, 2, 4].map(p => parseInt(hexcolor.substr(p, 2), 16));
  return ((r * 299) + (g * 587) + (b * 114)) / 1000 >= 128;
}

//DOC: valuta l'indirizzo email con una regex
export function validateEmail(email: string): boolean {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

/*DOC: setta i campi null dell'oggetto `base` con i valori dei rispettivi campi di `def`.
       i campi di `base` non presenti in `def` allora saranno ignorati. 
       se `strictly` è true allora i campi presenti in `def` ma non in `base`
       saranno creati in `base`.
       il parametro originale `base` viene modificato. */
export function setToDefaultValues(base: {}, def: {}, strictly: boolean = true): void {
  //console.log(base, def, strictly);
  Object.keys(def).forEach(c => {
    if ((base.hasOwnProperty(c) || strictly) && (!base[c] || base[c] === null))
      base[c] = def[c];
  })
  //console.log(base, def, strictly);
  //OPPURE ma con limitazioni: object3 = {...object1, ...object2 }
}


//DOC: restituisce un clone dell'oggetto `src` passato.
//FIXME: cambiare implementazione se possibile https://stackoverflow.com/questions/12690107/clone-object-without-reference-javascript
export function cloneObject(src: any): any {
  return JSON.parse(JSON.stringify(src));
}
