import { Database, Path, ChildPath, Action, Query } from 'services/database';
import { Authentication } from 'services/authentication';
import { autoinject, computedFrom, bindable } from 'aurelia-framework';

import { Log } from 'definitions';

import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';
import * as Utils from 'helpers/utils';

@autoinject
export class SecurityPage {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  //DOC: elenco dei logs
  @bindable logs: Log[] = [];
  //DOC: numero della pagina visualizzata 
  @bindable currentPage: number = 0; //NOTE: partendo da 0
  //DOC: numero di logs per pagina
  @bindable logsPerPage: number = 20;
  //DOC: checkbox per visualizzare solo log riguardanti la sicurezza
  @bindable onlySecurity: boolean = false;

  //DOC: array di query firebase che si utilizzano nel modello
  public queries: Query[] = [];

  constructor(private db: Database, private auth: Authentication) { }

  bind() {
    //DOC: sottoscrivo eventi: ON sui logs
    this.queries = [this.db.query(Path.logs).orderByChild(ChildPath.idUser).equalTo(this.auth.getCurrentUser().uid)];
    this.queries[0].on(Action.onValue, (data: any) => this.onLogsRead.call(this, data));
    //this.queries.forEach(q => console.log(q.toString()));
    window.scrollTo(0, 0);
  }
  unbind() {
    //DOC: disinscrivo tutti gli eventi
    this.queries.forEach(q => q.off());
  }

  //DOC: callback richiamata quando ottengo i logs dell'utente
  public onLogsRead(data: any) {
    this.logger.log("Dati dei logs dell'utente letti o aggiornati");
    if (data.val() != null) {
      this.logs = Utils.toArrayOfObjects(data.val());
      //console.log(this.logs.sort(v => (v as Log).dateTime).map((v, i) => i + " - " + (v as Log).dateTime).join("\n"));
      this.currentPage = 0;
    }
  }
  //DOC: se setto la visualizzazione su onlySecurity resetto la pagina corrente a 0 
  public onlySecurityChanged(_new, _old) {
    if (_new) this.currentPage = 0;
  }

  //DOC: restituisce il numero di pagine che variano in fz del numero di log 
  @computedFrom('logs.length', 'onlySecurity')
  get numberOfPages() {
    if (this.onlySecurity) return Math.ceil(this.logs.filter(v => v.security == true).length / this.logsPerPage);
    return Math.ceil(this.logs.length / this.logsPerPage);
  }

  //DOC: restituisce l'array dei log filtrato se onlySecurity, ordinato cronologicamente desc e log fromLog toLog
  @computedFrom('logs.length', 'currentPage', 'onlySecurity')
  get listOfLogs() {
    return this.logs
      .filter(v => this.onlySecurity ? v.security : 1)
      .sort((v1, v2) => v2.dateTime - v1.dateTime)
      .slice(this.currentPage * this.logsPerPage, (this.currentPage + 1) * this.logsPerPage);
  }
}
