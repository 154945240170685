import { Log } from 'definitions';

import { autoinject, inject, computedFrom, singleton } from 'aurelia-framework';
import { Container } from 'aurelia-dependency-injection';

import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';
import { Database } from 'services/database';

import * as Firebase from 'firebase/app';
import 'firebase/auth';

//DOC: classe wrapper di firebase per la gestione della autenticazione (Firebase)
@autoinject
export class Authentication {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = (new Logger()).setStyle(Logger.colors.blue, Logger.colors.white).setDefaultPrefix("AUTH");

  //DOC: indica lo stato dell'utente (usato per i gets)
  private loggedStatusChanged: boolean;
  //DOC: indica il valore di currentUser (usato per i gets)
  private currentUserChanged: boolean = false;

  //DOC: oggetto autenticazione di Firebase
  private auth: Firebase.auth.Auth;

  //DOC: riferimento al Database di Firebase
  private _db: Database = null;
  //DOC: inizializza o restituisce il riferimento al Database instanziato
  private get db(): Database {
    if (!this._db) this._db = this.container.get(Database);
    return this._db;
  };

  constructor(private container: Container) {
    //DOC: inizializzazione dell'Authentication
    this.auth = Firebase.auth();
    this.logger.success("Firebase Authentication", "inizializzato correttamente");
  }

  //NOTE: GETTERs
  //DOC: indica se l'utente è loggato
  @computedFrom('loggedStatusChanged')
  get isLogged(): boolean {
    return this.auth.currentUser != null;
  }
  //DOC: indica se currentUser è cambiato
  @computedFrom('currentUserChanged')
  get isCurrentUserChanged(): boolean {
    return this.currentUserChanged;
  }

  //DOC: evento al cambiamento di stato dell'autenticazione
  public onAuthStateChanged(callback: (user: any) => any, context: any) {
    this.auth.onAuthStateChanged(
      (user) => {
        this.loggedStatusChanged = (user == null || !user) ? false : true;
        //NOTE: aggiorno l'uid dell'utente corrente in Database
        callback.call(context, (user == null || !user) ? false : user);
      });
  }

  //DOC: restituisce l'utente corrente
  public getCurrentUser(): Firebase.User {
    /*FIXME: il problema è che se sloggo nella pagina dove sono viene eseguito unbind e passo uid di un utente che non è più loggato
     così risolvo ma forse devo cambiare anche se logicamente se sono sloggato uid deve essere invalido (null|-1|whatever)*/
    return this.auth.currentUser || <Firebase.User>{ uid: "" };
  }
  //DOC: login dato username e password
  public loginWithUsernameAndPassword(email: string, password: string): Promise<any> {
    return new Promise<any>((ok, ko) =>
      this.auth.signInWithEmailAndPassword(email, password)
        .then(_ => {
          this.db.appendLog(
            {
              dateTime: (new Date()).getTime(),
              uid: this.getCurrentUser().uid,
              subject: "AUTH.login",
              operation: "Signin with email and password",
              security: true
            } as Log);
          this.logger.success("Loggato con successo");
        })
        .then(_ => ok())
        .catch((error) => {
          this.errorManager.error(error);
          ko();
        }));
  }

  //DOC: logout
  public logout(): Promise<any> {
    var uid = this.getCurrentUser().uid;
    return new Promise<any>((ok, ko) => this.auth.signOut()
      .then(_ => {
        this.db.appendLog(
          {
            dateTime: (new Date()).getTime(),
            uid: uid,
            subject: "AUTH.sign_out",
            operation: "Signed out",
            security: true
          } as Log);
        this.logger.success("Sloggato con successo");
      })
      .then(_ => ok())
      .catch((error) => {
        this.errorManager.error(error);
        ko();
      }));
  }

  //DOC: registrazione di un nuovo utente nel sistema
  public registration(email: string, password: string): Promise<any> {
    return new Promise<any>((ok, ko) => {
      this.auth.createUserWithEmailAndPassword(email, password)
        .then(_ => {
          this.db.appendLog(
            {
              dateTime: (new Date()).getTime(),
              uid: this.getCurrentUser().uid,
              subject: "AUTH.registration",
              operation: "Registration with email and password",
              security: true
            } as Log);
          this.logger.success("Utente creato con successo");
        })
        .then(_ => ok())
        .catch((error) => {
          this.errorManager.error(error);
          return ko();
        });
    });
  }

  //DOC: aggiorna displayName e photo URL del profilo (passo un oggetto che contiene i due valori)
  public updateProfile(profile: { displayName?: string, photoURL?: string }): Promise<any> {
    let user = this.auth.currentUser;
    if (user != null) {
      // se uno dei due parametri manca allora lo inizializzo con l'esistente
      profile.displayName = profile.displayName || user.displayName;
      profile.photoURL = profile.photoURL || user.photoURL;
      return new Promise<any>((ok, ko) => user.updateProfile(profile as { displayName: string, photoURL: string })
        .then(_ => this.currentUserChanged = !this.currentUserChanged)
        .then(_ => this.db.appendLog(
          {
            dateTime: (new Date()).getTime(),
            uid: this.getCurrentUser().uid,
            subject: "AUTH.update",
            operation: "Updated profile (username or profile image)",
            security: true
          } as Log))
        .then(_ => ok())
        .catch((error) => {
          this.errorManager.error(error);
          ko();
        }));
    }
    else {
      this.logger.error("Authentication", "user dovrebbe essere non null per fare l'update del profilo (da gestire con errorManager)");
    }
  }


  //DOC: aggiorna email
  public updateEmail(password: string, oldEmail: string, newEmail: string): Promise<any> {
    //NOTE: Per aggiornare la mail l'utente deve essere loggato recentemente 
    //(quindi prima lo riautentico con reAuthenticateUser)
    return new Promise<any>((ok, ko) =>
      this.reAuthenticateUser(oldEmail, password)
        .then(_ => {
          this.auth.currentUser.updateEmail(newEmail)
            .then(_ => this.db.appendLog(
              {
                dateTime: (new Date()).getTime(),
                uid: this.getCurrentUser().uid,
                subject: "AUTH.update",
                operation: "Updated email",
                security: true
              } as Log))
            .then(_ => {/*//TODO: inviare mail per notificare cambiamento password */ })
            .then(_ => ok())
            .catch((error) => {
              this.errorManager.error(error);
              ko();
            });
        }))
  }

  //DOC: imposta una nuova password all'account utente dopo averlo ri-autenticato
  public updatePassword(email: string, oldPassword: string, newPassword: string): Promise<any> {
    //NOTE: Per aggiornare la password l'utente deve essere loggato recentemente 
    //(quindi prima lo riautentico con reAuthenticateUser)
    return new Promise<any>((ok, ko) =>
      this.reAuthenticateUser(email, oldPassword)
        .then(_ => {
          this.auth.currentUser.updatePassword(newPassword)
            .then(_ => this.db.appendLog(
              {
                dateTime: (new Date()).getTime(),
                uid: this.getCurrentUser().uid,
                subject: "AUTH.update",
                operation: "Updated password",
                security: true
              } as Log))
            .then(_ => {/*//TODO: inviare mail per notificare cambiamento password */ })
            .then(_ => ok())
            .catch((error) => {
              this.errorManager.error(error);
              ko();
            });
        }))
  }

  //DOC: invia email di reset password
  public sendPasswordResetEmail(email: string): Promise<any> {
    return new Promise<any>((ok, ko) =>
      this.auth.sendPasswordResetEmail(email)
        .then(_ => {
          this.logger.info("Mail per reset della password inviata correttamente a " + email);
          this.db.appendLog(
            {
              dateTime: (new Date()).getTime(),
              uid: this.getCurrentUser().uid,
              subject: "AUTH.reset_email",
              operation: "Sent email to reset the password",
              security: true
            } as Log);
        })
        .then(_ => ok())
        .catch((error) => {
          this.errorManager.error(error);
          ko();
        }));
  }

  //DOC: invia mail di verifica account
  public sendEmailVerification(): void {
    this.auth.currentUser.sendEmailVerification()
      .then(_ => this.db.appendLog(
        {
          dateTime: (new Date()).getTime(),
          uid: this.getCurrentUser().uid,
          subject: "AUTH.email_verification",
          operation: "Sent email to confirm the account registration",
          security: true
        } as Log))
      .catch((error) => {
        this.errorManager.error(error);
      });
  }

  //TODO: cancella utente
  public deleteUser() {
    //NOTE: the user must have signed in recently. See Re-authenticate a user.
  }

  //DOC: riautentica l'utente 
  public reAuthenticateUser(email: string, password: string): Promise<any> {
    var cred = Firebase.auth.EmailAuthProvider.credential(email, password);
    return new Promise<any>((ok, ko) =>
      this.auth.currentUser.reauthenticateAndRetrieveDataWithCredential(cred)
        .then(_ => this.db.appendLog(
          {
            dateTime: (new Date()).getTime(),
            uid: this.getCurrentUser().uid,
            subject: "AUTH.re_authentication",
            operation: "Re authentication to perform a security-sensitive action",
            security: true
          } as Log))
        .then(_ => ok())
        .catch((error) => {
          this.errorManager.error(error);
          ko();
        }));
  }

}
