import { autoinject, bindable, signalBindings, computedFrom, DOM } from 'aurelia-framework';
import { BindingEngine } from 'aurelia-binding';
import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';
import { Database, Path, ChildPath, Action, Query } from 'services/database';
import { Curriculum, Category, Functions, defaultCategory } from 'definitions';

import './css/curriculum-component.css';
import { LocalResources } from 'services/storage';

@autoinject
export class CurriculumComponent {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  //DOC: oggetto curriculum del modello DB
  @bindable curriculum: Curriculum = { id: "", categories: [] };

  //DOC: indica se l'elemento è nello stato di EDIT, se false è in stato di VIEW
  @bindable canEdit: boolean = true;
  //DOC: indica se in stato di EDIT si sta modificando
  @bindable isEditing: boolean = false;

  //DOC: indica se sta caricando le categorie contenute
  @bindable isLoadingCategories: number = -1;

  //DOC: array di query firebase che si utilizzano nel modello
  public queries: Query[] = [];

  @bindable currentIdCategoryInViewport: string = "";
  public onscroll = () => { };

  //DOC: evento sollevato al completamento del caricamento delle categorie
  @bindable public onLoadingCompleted: ({ idCurriculum: string }) => void;

  constructor(private db: Database, public element: Element, private bengine: BindingEngine) { }

  bind() {
    //DOC: sottoscrivo eventi: ON sul curriculum, ADDED e REMOVED su category
    this.queries = [
      this.db.query(Path.curricula).orderByChild(ChildPath.idUser).equalTo(this.curriculum.uid),
      this.db.query(Path.categories).orderByChild(ChildPath.idCurriculum).equalTo(this.curriculum.id)];
    this.queries[0].on(Action.onChanged, (data: any) => this.onCurriculumChanged.call(this, data));
    this.queries[1].on(Action.onAdded, (data: any) => this.onCategoryAdded.call(this, data));
    this.queries[1].on(Action.onRemoved, (data: any) => this.onCategoryRemoved.call(this, data));
    //this.queries.forEach(q => console.log(q.toString()));

    this.curriculum.categories = [];
    //|| {} è perchè il curriculum potrebbe non avere categorie
    this.isLoadingCategories = Object.keys((this.curriculum as any).idCategories || {}).length;

    //NOTE: funzione inizializzata che è callback all'evento scroll (NB: deve essere inizializzata nel ctor)
    this.onscroll = () => {
      setTimeout(() => {
        let scroll = window.pageYOffset | document.body.scrollTop;
        let elements = document.querySelectorAll("category-component > div, #personalInfo, #coverLetter");
        for (let i = 0; i < elements.length; i++) {
          let el = elements.item(i) as HTMLElement;
          let rect = el.getBoundingClientRect();
          let offset = { top: rect.top + document.body.scrollTop, left: rect.left + document.body.scrollLeft }
          if (offset.top >= scroll && el.offsetParent !== null) {
            //console.log("--->", el.id);
            this.currentIdCategoryInViewport = el.id;
            break;
          }
        }
      }, 100);
    };
  }
  unbind() {
    //DOC: disinscrivo tutti gli eventi
    this.queries.forEach(q => q.off());
  }
  attached() {
    document.addEventListener('scroll', this.onscroll);
  }

  detached() {
    document.removeEventListener('scroll', this.onscroll);
  }

  //DOC: salva i cambiamenti del curriculum
  public saveChanges(value: any) {
    this.db.updateCurriculum(this.curriculum.id, value);
  }

  //DOC: aggiunge una nuova categoria al curriculum
  public addCategory(pos: number) {
    //controllo che sto aggiungendo la categoria in una posizione valida
    if (pos == null || pos < 0 || pos > Functions.maxPosition(this.curriculum.categories) + 1)
      throw new Error("Posizione di inserimento della categoria non valida");
    let cat: Category = { ...defaultCategory, idCurriculum: this.curriculum.id, position: pos, icon: LocalResources.noIcon };
    //filtro le categorie di posizione maggiore ed incremento la loro posizione
    let updates: Category[] = [];
    this.curriculum.categories
      .filter(c => c.position >= pos)
      .forEach(c => updates.push({ id: c.id, position: (c.position = c.position + 1) }));
    this.db.updateCategory(updates);
    //aggiungo la nuova categoria
    this.db.appendCategory(cat);
  }

  //DOC: elimina la categoria
  public deleteCategory(idCategory: string, idCurriculum: string) {
    if (!window.confirm('Do you really want to delete the categories?')) return;
    let pos = this.curriculum.categories.find(c => c.id == idCategory).position;
    //filtro gli elementi di posizione maggiore e decremento la loro posizione in db ed in locale
    let updates: Category[] = [];
    this.curriculum.categories
      .filter(c => { c.position > pos && c.id != idCategory })
      .forEach(c => { updates.push({ id: c.id, position: (c.position = c.position - 1) }) });
    this.db.updateCategory(updates);
    //elimino la categoria
    this.db.deleteCategory({ id: idCategory, idCurriculum: idCurriculum });
  }

  //DOC: callback alla modifica del curriculum
  public onCurriculumChanged(data: any) {
    this.logger.log("Curriculum Modificato");
    if (!data.exists())
      this.errorManager.error("onCurriculumChanged: data not valid");
    let d = data.val();
    //NOTE: per ogni campo controllo che sia stato modificato e quindi lo aggiorno nel modello
    Object.entries(d).forEach(
      ([key, value]) => {
        if (this.curriculum[key] != value) {
          this.curriculum[key] = value;
          console.log(key + " -> " + value);
        }
      }
    );
    this.changeCurriculumStyle();
  }

  //DOC: callback all'aggiunta di una categoria
  public onCategoryAdded(data: any) {
    this.logger.log("Categoria effettivamente aggiunta al DB");
    let c = data.val() as Category;
    c.id = data.key;
    // aggiungo la categoria al cv
    if (this.curriculum.categories.some(_ => _.id == c.id))
      throw new Error("La categoria che si vuole aggiungere è già presente");
    this.curriculum.categories.splice(this.curriculum.categories.length, 0, c);
  }
  //DOC: callback alla rimozione di una categoria
  public onCategoryRemoved(data: any) {
    this.logger.log("Categoria effettivamente eliminata dal DB");
    // elimino la categoria dal cv
    let index = this.curriculum.categories.findIndex(c => c.id == data.key);
    let pos = this.curriculum.categories.find(c => c.id == data.key).position;
    if (index == -1)
      throw new Error("La categoria che si vuole eliminare non è stato trovata");
    this.curriculum.categories.splice(index, 1);
    // filtro le categorie di posizione maggiore e decremento la loro posizione
    this.curriculum.categories
      .filter(c => c.position >= pos)
      .forEach(c => --c.position);
  }

  //DOC: scambio tra loro le categorie di posizione p e PRECEDENTE(p)
  public moveUpCategory(p: number) {
    if (p <= Functions.minPosition(this.curriculum.categories))
      throw new Error("Posizione di spostamento della categoria non valida");
    this.logger.log("sposto su", p);
    //trovo le categorie da modificare
    let c1 = this.curriculum.categories.find(c => c.position == p);
    let pprevious = Functions.previousPosition(this.curriculum.categories, p);
    let c2 = this.curriculum.categories.find(c => c.position == pprevious);
    //aggiorno le posizioni delle categorie in db ed in locale
    this.db.updateCategory([
      { id: c1.id, position: (c1.position = c2.position) },
      { id: c2.id, position: (c2.position = p) }])
      .then(() => {
        //chiamo il segnale per il ValueConverter
        signalBindings('position-changed');
      });
  }

  //DOC: scambio tra loro le categorie di posizione p e SUCCESSIVO(p)
  public moveDownCategory(p: number) {
    if (p >= Functions.maxPosition(this.curriculum.categories))
      throw new Error("Posizione di spostamento della categoria non valida");
    this.logger.log("sposto giù", p);
    //trovo le categorie da modificare
    let c1 = this.curriculum.categories.find(c => c.position == p);
    let pnext = Functions.nextPosition(this.curriculum.categories, p);
    let c2 = this.curriculum.categories.find(c => c.position == pnext);
    //aggiorno le posizioni delle categorie in db ed in locale
    this.db.updateCategory([
      { id: c1.id, position: (c1.position = c2.position) },
      { id: c2.id, position: (c2.position = p) }])
      .then(() => {
        //chiamo il segnale per il ValueConverter
        signalBindings('position-changed');
      });
  }

  //DOC: restituisce true se ci sono categorie nel curriculum
  @computedFrom("curriculum.categories.length")
  get curriculumHasCategories(): boolean {
    return this.curriculum.categories.length != 0;
  }

  //DOC: al cambiamento di valore di isLoadingElements solleva l'evento per il termine del caricamento delle categorie
  public isLoadingCategoriesChanged(_new: number, _old: number) {
    if (_new == 0) {
      this.changeCurriculumStyle();
      this.onLoadingCompleted({ idCurriculum: this.curriculum.id });
    }
  }
  //DOC: evento sollevato al termine del caricamento della singola categoria
  public onCategoryLoaded(idCategory: string /*DoNotDelete*/) {
    //console.log('caricamento categoria completato: ', idCategory);
    this.isLoadingCategories--;
  }


  //TODO: splittare le azioni di update dopo la singola modifica (update puntuale)
  public changeCurriculumStyle() {
    let root = document.querySelector(".curriculum-component") as HTMLElement;
    if (!root) return;
    root.style.setProperty("--text-color-primary", this.curriculum.style.textColorPrimary);
    root.style.setProperty("--text-color-secondary", this.curriculum.style.textColorSecondary);
    root.style.setProperty("--text-color-more", this.curriculum.style.textColorMore);
    root.style.setProperty("--text-size-ratio", this.curriculum.style.textSizeRatio + "rem");
    if (this.curriculum.style.layourBorder) root.classList.replace("container-fluid", "container");
    else root.classList.replace("container", "container-fluid");
    root.style.setProperty("--icons-enabled", this.curriculum.style.iconsEnabled ? "visible" : "hidden");

    let linktag = document.querySelector('#googlefont') as HTMLLinkElement;
    let fontFamily = this.curriculum.style.textFamily //NOTE: deve essere del formato: 'Permanent Marker', cursive
    let fontLink = fontFamily.split(',')[0].replace(/'/g, '').replace(/ /g, '+');
    linktag.href = `https://fonts.googleapis.com/css?family=${fontLink}`;
    root.style.setProperty("--text-family", this.curriculum.style.textFamily);
  }

}
