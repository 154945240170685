import { autoinject, bindable, computedFrom, signalBindings } from 'aurelia-framework';
import { Database, Path, ChildPath, Action, Query } from 'services/database';
import { LocalResources } from 'services/storage';
import { Category, Element as Elem, Functions, defaultElement } from 'definitions';
import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';

import './css/category-component.css';

@autoinject
export class CategoryComponent {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  //DOC: oggetto categoria del modello DB
  @bindable category: Category;

  //DOC: path base delle immagini, in funzione della definizione in webpack
  @bindable iconBasePath: string = "/images/";

  //DOC: indica se l'elemento è nello stato di EDIT, se false è in stato di VIEW e niente può essere modificato
  @bindable canEdit: boolean = true;
  //DOC: indica, se in stato di EDIT, si sta modificando
  @bindable isEditing: boolean = false;

  //DOC: indica se sta caricando gli elementi contenuti (0 se ha caricato tutti gli elementi)
  @bindable isLoadingElements: number = -1;

  //DOC: proprietà settata da padre, se true è la prima categoria in elenco
  @bindable inFirstPosition: boolean = false;
  //DOC: proprietà settata da padre, se true è l'ultima categoria in elenco
  @bindable inLastPosition: boolean = false;

  //DOC: array di query firebase che si utilizzano nel modello
  public queries: Query[] = [];

  //DOC: eventi da bindare per lo spostamento della posizione delle categorie
  @bindable public onMoveUpPosition: (p: number) => void;
  @bindable public onMoveDownPosition: (p: number) => void;
  //DOC: eventi sollevati in CurriculumComponent per l'aggiunta o l'eliminazione delle categorie
  @bindable public onAddingCategory: (position: number) => void;
  @bindable public onDeletingCategory: (idCategory: string, idCurriculum: string) => void;

  //DOC: evento sollevato al completamento del caricamento degli elementi
  @bindable public onLoadingCompleted: ({ idCategory: string }) => void;

  constructor(private db: Database, public element: Element) { }

  bind() {
    //DOC: sottoscrivo eventi: onChanged su Category, onAdded e onRemoved su Element
    this.queries = [
      this.db.query(Path.categories).orderByKey().equalTo(this.category.id),
      this.db.query(Path.elements).orderByChild(ChildPath.idCategory).equalTo(this.category.id)];
    this.queries[0].on(Action.onChanged, (data: any) => this.onCategoryChanged.call(this, data));
    this.queries[1].on(Action.onAdded, (data: any) => this.onElementAdded.call(this, data));
    this.queries[1].on(Action.onRemoved, (data: any) => this.onElementRemoved.call(this, data));
    //this.queries.forEach(q => console.log(q.toString()));

    this.category.elements = [];
    //|| {} è perchè la categoria potrebbe non avere elementi
    this.isLoadingElements = Object.keys((this.category as any).idElements || {}).length;
  }
  unbind() {
    //DOC: disinscrivo tutti gli eventi
    this.queries.forEach(q => q.off());
  }
  attached() { }

  //DOC: salva i cambiamenti della categoria
  public saveChanges(value: any) {
    value.id = this.category.id;
    this.db.updateCategory([value]);
  }

  //DOC: aggiunge un elemento alla categoria
  public addElement(pos: number) {
    if (pos == null || pos < 0 || pos > Functions.maxPosition(this.category.elements) + 1)
      throw new Error("Posizione di inserimento dell'elemento non valida");
    let ele: Elem = { ...defaultElement, idCategory: this.category.id, position: pos };
    console.log("----->", ele);
    //filtro gli elementi di posizione maggiore ed incremento la loro posizione
    let updates: Elem[] = [];
    this.category.elements
      .filter(e => e.position >= pos)
      .forEach(e => updates.push({ id: e.id, position: ++e.position }));
    this.db.updateElement(updates);
    //aggiungo il nuovo elemento
    this.db.appendElement(ele);
  }

  //DOC: elimina l'elemento
  public deleteElement(idElement: string, idCategory: string) {
    let pos = this.category.elements.find(e => e.id == idElement).position;
    //filtro gli elementi di posizione maggiore e decremento la loro posizione
    let updates: Elem[] = [];
    this.category.elements
      .filter(e => { e.position > pos && e.id != idElement })
      .forEach(e => { updates.push({ id: e.id, position: --e.position }) });
    this.db.updateElement(updates);
    //elimino l'elemento
    this.db.deleteElement({ id: idElement, idCategory: idCategory });
  }

  //DOC: callback alla modifica della categoria
  public onCategoryChanged(data: any) {
    this.logger.log("Categoria Modificata");
    if (!data.exists())
      this.errorManager.error("onCategoryChanged: data not valid");
    let d = data.val();
    //NOTE: per ogni campo controllo che sia stato modificato e quindi lo aggiorno nel modello
    Object.entries(d).forEach(
      ([key, value]) => {
        if (this.category[key] != value) {
          this.category[key] = value;
          console.log(key + " -> " + value);
        }
      }
    );
  }

  //DOC: callback all'aggiunta di un elemento
  public onElementAdded(data: any) {
    this.logger.log("Elemento effettivamente aggiunto al DB");
    let e = data.val() as Elem;
    e.id = data.key;
    // aggiungo l'elemento alla categoria
    if (this.category.elements.some(_ => _.id == e.id))
      throw new Error("L'elemento che si vuole aggiungere è già presente");
    this.category.elements.splice(this.category.elements.length, 0, e);
    this.isLoadingElements--;
  }
  //DOC: callback alla rimozione di un elemento
  public onElementRemoved(data: any) {
    this.logger.log("Elemento effettivamente eliminato dal DB");
    // elimino l'elemento dalla categoria
    let index = this.category.elements.findIndex(e => e.id == data.key);
    let pos = this.category.elements.find(e => e.id == data.key).position;
    if (index == -1)
      throw new Error("L'elemento che si vuole eliminare non è stato trovato");
    this.category.elements.splice(index, 1);
    // filtro gli elementi di posizione maggiore e decremento la loro posizione
    this.category.elements
      .filter(e => e.position >= pos)
      .forEach(e => --e.position);
  }

  //DOC: scambio tra loro gli elementi di posizione p e PRECEDENTE(p)
  public moveUpElement(p: number) {
    if (p <= Functions.minPosition(this.category.elements))
      throw new Error("Posizione di spostamento dell'elemento non valida");
    this.logger.log("sposto su", p);
    //trovo gli elementi da modificare
    let e1 = this.category.elements.find(c => c.position == p);
    let pprevious = Functions.previousPosition(this.category.elements, p);
    let e2 = this.category.elements.find(c => c.position == pprevious);
    //aggiorno le posizioni degli elementi in db ed in locale
    this.db.updateElement([
      { id: e1.id, position: (e1.position = e2.position) },
      { id: e2.id, position: (e2.position = p) }])
      .then(() => {
        //chiamo il segnale per il ValueConverter
        signalBindings('position-changed');
      });
  }

  //DOC: scambio tra loro gli elementi di posizione p e SUCCESSIVO(p)
  public moveDownElement(p: number) {
    if (p >= Functions.maxPosition(this.category.elements))
      throw new Error("Posizione di spostamento dell'elemento non valida");
    this.logger.log("sposto giù", p);
    //aggiorno le posizioni
    let e1 = this.category.elements.find(c => c.position == p);
    let pnext = Functions.nextPosition(this.category.elements, p);
    let e2 = this.category.elements.find(c => c.position == pnext);
    //aggiorno le posizioni degli elementi in db ed in locale
    this.db.updateElement([
      { id: e1.id, position: (e1.position = e2.position) },
      { id: e2.id, position: (e2.position = p) }])
      .then(() => {
        //chiamo il segnale per il ValueConverter
        signalBindings('position-changed');
      });

  }

  //DOC: restituisce lista di icone (stringhe) del pack p
  public getIconsInPack(p: number = 0) {
    return LocalResources.icons[p].icons.concat(LocalResources.noIcon);
  }

  //DOC: restituisce true se l'icona scelta è valida (cioè se non ho selezionato noIcon)
  @computedFrom("category.icon")
  get isValidIcon(): boolean {
    return this.category.icon != LocalResources.noIcon;
  }

  //DOC: restituisce il percorso dell'icona della categoria
  @computedFrom("category.icon")
  get iconPath() {
    return this.iconBasePath + this.category.icon;
  }

  //DOC: restituisce il testo per l'attributo ALT del tag IMAGE
  @computedFrom("category.title")
  get imgAltAttr() {
    return this.category.title ? this.category.title.toLowerCase() : "";
  }

  //DOC: restituisce true se ci sono elementi nella categoria
  @computedFrom("category.elements.length")
  get categoryHasElements(): boolean {
    return this.category.elements.length != 0;
  }

  //DOC: al cambiamento di valore di isLoadingElements solleva l'evento per il termine del caricamento degli elementi
  public isLoadingElementsChanged(_new: number, _old: number) {
    if (_new == 0) this.onLoadingCompleted({ idCategory: this.category.id });
  }
}
