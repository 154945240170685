import { computedFrom, bindable, autoinject } from 'aurelia-framework';

import './css/footer-section.css'

@autoinject
export class FooterSection {
  @bindable icons: string[] = ["heart", "coffee", "mug-hot", "brain",
    "ice-cream", "pizza-slice", "hotdog", "bacon", "hand-peace", "handshake", "praying-hands",
    "dog", "hippo", "dragon", "frog"];
  @bindable iconPos: number = 0;

  //DOC: indica se il page-content (cv) è in modalità focus (con navbar e footer ridotti)
  @bindable focusMode: boolean = false;

  constructor() { }

  bind() {
    setInterval(
      () => {
        this.iconPos = Math.floor(Math.random() * this.icons.length);
      }, 2000);
  }

  //DOC: evento al cambiamento di focusMode per scrollare al bottom della pagina
  public focusModeChanged(_new, _old) {
    if (!_new)
      setTimeout(() => {
        document.querySelector('footer-section').scrollIntoView({ behavior: "smooth", block: "end" });
      }, 250);
  }

  //DOC: restituisce l'icona formattata font-awesome
  @computedFrom('iconPos')
  get iconString(): string {
    return "fas fa-" + this.icons[this.iconPos];
  }
  //DOC: restituisce il nome dell'icona per il tooltip
  @computedFrom('iconPos')
  get iconName(): string {
    return this.icons[this.iconPos];
  }
}
