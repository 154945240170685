import { autoinject, bindable, computedFrom } from 'aurelia-framework'
import { BindingSignaler } from 'aurelia-templating-resources';
import { Router } from 'aurelia-router';
import { Curriculum, Element } from 'definitions';

import { Database, Path, ChildPath, Action, Query } from 'services/database';

import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger'

import './css/page-index-component.css';

interface link {
    title: string;
    id: string;
}

@autoinject
export class PageIndexComponent {
    //DOC: gestore degli errori
    private errorManager: ErrorManager = new ErrorManager();
    //DOC: gestore della console
    private logger: Logger = new Logger();

    //DOC: oggetto elemento del modello DB
    @bindable curriculum: Curriculum;

    //DOC: stato del menu
    @bindable menuOpen: boolean = false;

    //DOC: id della categoria in viewport
    @bindable currentIdCategoryInViewport: string = "";

    //DOC: array di links aggiuntivi aggiunti in testa
    @bindable moreLinks: link[] = [];

    constructor(private db: Database, private signaler: BindingSignaler) { }

    bind() {
        //DOC: sottoscrivo eventi: onChanged su Element
        //this.queries = [this.db.query(Path.elements).orderByKey().equalTo(this.element.id)];
        //this.queries[0].on(Action.onChanged, (data: any) => this.onElementChanged.call(this, data));
        //this.queries.forEach(q => console.log(q.toString()));
    }
    unbind() {
        //DOC: disinscrivo tutti gli eventi
        //this.queries.forEach(q => q.off());
    }

    //DOC: modifica lo stato del menu
    public setMenuState(s: boolean) {
        this.menuOpen = s;
    }

    //DOC: scrolla la pagina fino all'elemento passato (NB: id elemento = id categoria)
    public gotoLink(id: string): void {
        document.getElementById(id).scrollIntoView({ behavior: "smooth", block: "start" });
        document.getElementById(id).children[0].classList.add('goto-focus');
    }

    //DOC: metodo chiamato al cambiamento di valore di currentIdCategoryInViewport per aggiornare la view
    public currentIdCategoryInViewportChanged(_new: string, _old: string) {
        //DOC: invio il segnale alla view che notifica il nuovo id della categoria in viewport
        this.signaler.signal('update-category-in-viewport');
    }

    //DOC: restituisce il css per il singolo link in base alla presenza in viewport della relativa categoria
    public inViewportCss(id: string): string {
        if (!id) return;
        //console.log(id, " =?= ", this.currentIdCategoryInViewport);
        return id == this.currentIdCategoryInViewport ? 'in-viewport' : '';
    }

    //DOC: restituisce il css relativo allo stato del menu
    @computedFrom('menuOpen')
    get menuCss(): string {
        return this.menuOpen ? "menu-open" : "menu-closed";
    }
}