import { Router, NavModel } from 'aurelia-router';
import { bindable, autoinject, computedFrom } from "aurelia-framework";
import { Route } from 'definitions';

import { Logger } from 'helpers/logger';
import { ErrorManager } from 'helpers/errorManager';
import { Authentication } from 'services/authentication';
import { Storage_, LocalResources } from 'services/storage';

import "./css/navbar-section.css";

@autoinject
export class NavbarSection {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  //DOC: indica se il page-content (cv) è in modalità focus (con navbar e footer ridotti)
  @bindable focusMode: boolean = false;
  
  constructor(private router: Router, private auth: Authentication, private storage: Storage_) {
    this.auth.onAuthStateChanged(this.onUserLogged, this);
  }

  //DOC: callback chiamata quando l'utente si logga o si slogga
  public onUserLogged(user: any) { }

  //DOC: restituisce il displayName dell'utente loggato
  @computedFrom('auth.isCurrentUserChanged')
  get displayName() {
    if (this.auth.isLogged)
      return this.auth.getCurrentUser().displayName;
    else
      return null;
  }

  //DOC: restituisce la photoURL dell'utente loggato
  @computedFrom('auth.isCurrentUserChanged')
  get photoURL() {
    if (this.auth.getCurrentUser().photoURL != "" && this.auth.getCurrentUser().photoURL != null)
      return this.auth.getCurrentUser().photoURL;
    //NOTE: se photoURL è vuoto oppure null restituisco immagine di default
    return LocalResources.profile.default;
  }

  //DOC: restituisce i routes in funzione dello stato di login ed in posizione left sulla nav
  @computedFrom("auth.isLogged")
  get getLeftRoutes() {
    return this.router.navigation.filter(r => {
      let R = r.config as Route;
      return (R.visibility == 'always' || ((R.visibility == 'logged') == this.auth.isLogged))
        && R.nav
        && R.navPosition == 'left'
    });
  }

  //DOC: restituisce i routes per logged=true in posizione right sulla nav
  @computedFrom("auth.isLogged")
  get getRightRoutes() {
    return this.router.navigation.filter(r => {
      let R = r.config as Route;
      return (R.visibility == 'always' || ((R.visibility == 'logged') == this.auth.isLogged))
        && R.nav
        && R.navPosition == 'right'
    });
  }

  //DOC: indica se l'utente è loggato
  //NOTE: (wrapper perchè auth è private)
  @computedFrom("auth.isLogged")
  get isLogged(): boolean {
    return this.auth.isLogged;
  }
}
