import { observable, computedFrom, bindable, autoinject } from 'aurelia-framework';

import { Logger } from 'helpers/logger';
import { ErrorManager } from 'helpers/errorManager';

import './css/index-page.css';

@autoinject
export class IndexPage {
    //DOC: gestore degli errori
    private errorManager: ErrorManager = new ErrorManager();
    //DOC: gestore della console
    private logger: Logger = new Logger();

    constructor() { }

    bind() {
        window.scrollTo(0, 0);
    }

    @computedFrom('body.offsetHeight')
    get offsetHeight(): number {
        //console.log(document.body.offsetHeight);
        return document.body.offsetHeight - 100;
    }
}