import { RouteConfig } from 'aurelia-router';


export interface Curriculum {
  id: string;
  uid?: string;
  title?: string;
  lastUpdate?: number;
  archived?: boolean;
  lang?: string;
  style?: CurriculumStyle;
  links?: string;
  details?: string;
  description?: string;
  coverLetter?: string;
  accounts?: string;
  private?: boolean;
  categories?: Category[];
};
export interface Category {
  id: string;
  idCurriculum?: string;
  title?: string;
  position?: number;
  icon?: string;
  elements?: Element[];
};
export interface Element {
  id: string;
  idCategory?: string;
  position?: number;
  subPosition?: number;
  title?: string;
  description?: string;
  period?: string;
  tags?: string;
  idConfirm?: string;
  status?: ConfirmState | ""; //last status of the confirm history item
};
export interface User {
  id: string; //NOTE: uid in Firebase Authentication, key in Database
  name?: string;
  lastname?: string;
  address?: string;
  emails?: string[]; //NOTE: addional emails over the main one kept in the authentication record
  gender?: string;
  dob?: number; //NOTE: date of born
  dor?: number; //NOTE: date of registration
  lastLogin?: number;
  enabled?: boolean; //NOTE: verificated status in Authentication
};
export interface Log {
  dateTime: number; //NOTE: milliseconds
  uid: string;
  subject: string;
  operation: string;
  security: boolean;
};

export interface Route extends RouteConfig {
  //DOC: posizione della route sulla navbar
  navPosition?: 'left' | 'right',
  //DOC: visibilità della route in base allo stato del login dell'utente
  visibility: 'logged' | 'notlogged' | 'always' | 'never',
  //DOC: visualizzazione della route senza navbar
  hideNavbar: boolean,
  //DOC: se il link della route può/deve andare in overflow sulla navbar (es: dropdown)
  overflow: boolean,
  //DOC: callback al termine del caricamento di tutta la route
  onLoadingCompleted: () => void
}


//TODO: interfaccia dichiarata ma non utilizzata ancora
export interface CurriculumDetails {
  idCurriculum: string;
  nowords: number; //total number of words
  nocategories: number; //total number of categories
  noelements: number; //total number of elements
  changes: Date[]; //array of changes //NOTE: in futuro può essere un array di coppie (Data, idModifica)
}

export interface CurriculumStyle {
  textFamily?: string;
  textColorPrimary?: string;
  textColorSecondary?: string;
  textColorMore?: string;
  textSizeRatio?: number;
  // TODO: stile punti delle liste di primo e secondo livello
  iconsEnabled?: boolean;
  iconsPack?: string;

  layoutProportions?: string;
  layourBorder?: boolean;

  tagsStyle?: { category: string, color: string }[];
  tagsShape?: string;
  tagsSize?: number;
}

export interface Secret {
  id: string;
  message: string;
}
/*
  - scrivo del testo tra i tag {private}{/private}
  - quando salvo
  - quando salvo l'inputEditable lo sostituisce con l'html ***** (lo fa il markdown riconoscendo il tag)
  - c'è una classe che prende il segreto e crea un recordo Secret nel db (FUNCTIONS??)
    - nel valore dell'input il segreto è stato sostituito con un id univoco
  - quando visualizzo il cv, per ogni segreto cerco il valore corrispondente nel db
*/

export type ConfirmState = "requested" | "incomplete" | "approved" | "rejected";
//see docs/confirm_flow.pdf
export interface ConfirmHistoryItem {
  date: number;
  text: string;
  state: ConfirmState;
}
export interface Confirm {
  id: string;
  idCurriculum: string //id of the curriculum referred to
  idReviewer: string; //id of the reviewer
  idExperience: string; //id of the experience under reviewing
  history: ConfirmHistoryItem[]; /*each item is an action performed 
  by the user or the reviewer. it cannot be empty. last item status is the status of the entire confirm.
  the first item date is the request date of the confirm. */
}


export namespace Functions {
  //DOC: restituisce la più grande posizione delle categorie
  export function maxPosition(obj: { position?: number }[]) {
    return Math.max(...obj.map(o => o.position), 0);
  }
  //DOC: restituisce la più piccola posizione delle categorie
  export function minPosition(obj: { position?: number }[]) {
    return Math.min(...obj.map(o => o.position));
  }

  //DOC: restituisce la posizione successiva a quella passata (ovvero la più piccola delle maggiori di p)
  export function nextPosition(obj: { position?: number }[], p: number) {
    return Math.min(...obj.filter(v => v.position > p).map(o => o.position));
  }
  //DOC: restituisce la posizione precedente a quella passata (ovvero la più grande delle minori di p)
  export function previousPosition(obj: { position?: number }[], p: number) {
    return Math.max(...obj.filter(v => v.position < p).map(o => o.position));
  }

  //DOC: elimina il campo id, gli array e tutti i campi null per il salvataggio nel Database
  export function toDatabase(obj: { id: string }): any {
    let o: any = {};
    for (let p in obj)
      if (obj.hasOwnProperty(p) && obj[p] != null && !Array.isArray(p))
        o[p] = obj[p];
    delete o.id;
    return o;
  }

  //DOC: restituisce una stringa che rappresenta il log
  export function toString(log: Log): string {
    var isSecurity = log.security ? "[Security] " : "";
    return "[" + log.dateTime.toLocaleString() + "] " + log.subject + " => " + isSecurity + log.operation;
  }

  //TODO:
  export function getTagCategories(element: Element): string[] {
    //[HTML5]{.tag .cat} [CSS3]{.tag .cat} [Bootstrap]{.tag .cat} [JavaScript]{.tag .cat}
    //[jQuery]{.tag .cat} [PHP]{.tag .cat} [SQL]{.tag .cat}
    var cats: string[] = [];
    if (element.tags == "")
      return [""];
    element.tags.split("[").forEach(v => {
      let e = v.trim().replace("}", "").substring(v.lastIndexOf("."));
      //console.log(e); //aggiungo solo se valido e non già presente
      if (e != "" && !cats.includes(e))
        cats.push(e);
    })
    return cats;
  }
}


//DOC: oggetti di default
export const defaultElement: Element = {
  id: "",
  idCategory: "",
  period: "###### periodo",
  title: "### titolo",
  description: "#### descrizione"
}
export const defaultCategory: Category = {
  id: "",
  idCurriculum: "",
  title: "### titolo"
}
export const defaultCurriculum: Curriculum = {
  id: "",
  uid: "",
  title: "titolo",
  description: "descrizione"
}
export const defaultCurriculumStyle: CurriculumStyle = {
  textFamily: "'Raleway', sans-serif;",
  textColorPrimary: "#285d37".toUpperCase(),
  textColorSecondary: "#285d37".toUpperCase(),
  textColorMore: "#285d37".toUpperCase(),
  textSizeRatio: 1,
  iconsEnabled: true,
  iconsPack: "pack1",
  layoutProportions: "",
  layourBorder: false,
  tagsStyle: [],
  tagsShape: "",
  tagsSize: 1
}
