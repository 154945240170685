import { autoinject, inject } from 'aurelia-framework';
import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';

import * as Firebase from 'firebase/app';
import 'firebase/functions';

@autoinject
export class Functions {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = (new Logger()).setStyle(Logger.colors.teal, Logger.colors.white).setDefaultPrefix("FT");

  //DOC: oggetto Functions di Firebase
  private functions: Firebase.functions.Functions;

  constructor() {
    this.functions = Firebase.functions();
    this.logger.success("Firebase Functions", "inizializzato correttamente");
  }

  //DOC: metodo wrapper per chiamare una function ed ottenere il risultato
  public httpsCallable(fun: string): firebase.functions.HttpsCallable {
    return this.functions.httpsCallable(fun);
  }
}
