import { PLATFORM } from 'aurelia-pal';
import { observable, computedFrom, bindable, autoinject } from 'aurelia-framework';
import { Router, RouterConfiguration } from 'aurelia-router';

import "./css/protection-cv-page.css"

@autoinject
export class ProtectionCvPage {
  constructor(public router: Router) {
    window.scrollTo(0, 0);
  }

  configureRouter(config: RouterConfiguration, router: Router) {
    config.map([
      {
        route: ['', 'single'], name: 'protection/single',
        moduleId: PLATFORM.moduleName('pages/CvSubpages/ProtectionCvSubpages/SingleCodePage'), nav: true, title: 'Single Code'
      },
      {
        route: ['multiple'], name: 'protection/multiple',
        moduleId: PLATFORM.moduleName('pages/CvSubpages/ProtectionCvSubpages/MultipleCodePage'), nav: true, title: 'Multiple Code'
      }
    ]);
    this.router = router;
  }

  bind() { }

}
