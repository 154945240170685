import { Database, Path, ChildPath, Action, Query } from 'services/database';
import { Authentication } from 'services/authentication';
import { autoinject, computedFrom, bindable } from 'aurelia-framework';

import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';
import * as Utils from 'helpers/utils';

import { Storage_ } from 'services/storage';
import { User } from 'definitions';

@autoinject
export class EmailPage {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  //DOC: dati utente: id, mail primaria e altre mail
  private idUser: string;
  @bindable email: string = "";
  @bindable password: string = "";
  @bindable otherEmails: string[] = [];

  //DOC: nuova mail che si vuole aggiungere a otherEmails
  @bindable newEmail: string = "";

  //DOC: indica se l'utente ha inserito correttamente la password corrente per cambiare la mail
  @bindable reauthenticated: boolean = false;


  //DOC: indica se si stanno caricando i dati
  @bindable isLoadingData: boolean = true;

  //DOC: array di query firebase che si utilizzano nel modello
  public queries: Query[] = [];

  constructor(private db: Database, private auth: Authentication) { }

  bind() {
    this.email = this.auth.getCurrentUser().email;
    //DOC: sottoscrivo eventi: ON sui curricula, ON sull'utente
    this.queries = [this.db.query(Path.users).orderByKey().equalTo(this.auth.getCurrentUser().uid)];
    this.queries[0].on(Action.onValue, (data: any) => this.onUserRead.call(this, Utils.toArrayOfObjects(data.val())[0]));//NOTE: prendo il primo elemento dell'array (ma ce ne è solo uno)
    //this.queries.forEach(q => console.log(q.toString()));
    //TODO: aggiungere on modified perchè se cancello tutto il blocco delle mail lui non se ne accorge (da verificare di nuovo)
    window.scrollTo(0, 0);
  }
  unbind() {
    //DOC: disinscrivo tutti gli eventi
    this.queries.forEach(q => q.off());
  }

  //DOC: callback richiamata quando ottengo i dati dell'utente
  public onUserRead(data: any) {
    this.logger.log("Dati dell'utente letti o aggiornati");
    data = data as User;
    //console.log(data);
    this.idUser = data.id || this.idUser;
    this.otherEmails = data.otherEmails || this.otherEmails;
    this.isLoadingData = false;
  }

  //DOC: metodo che verifica la password corrente prima di importare la nuova
  public reAuthenticate() {
    this.auth.reAuthenticateUser(this.email, this.password)
      .then(_ => { console.log("reauthenticated with success"); this.reauthenticated = true; })
      .catch(_ => console.log("got some trouble"));
  }

  //DOC: metodo per aggiornare la password
  public updateEmail() {
    if (this.newEmail == "") return;
    if (true/*TODO: check email with regex*/ /*&& this.email != this.newEmail*/) {
      console.log("Aggiornamento email da ", this.email, " a ", this.newEmail);
      this.auth.updateEmail(this.password, this.email, this.newEmail)
        .then(_ => {
          alert("Email address correctly changed!\nWe've sent an email to the new address to verify it");
          this.reauthenticated = false;
        });
    }
    else {
      alert("The email address is not valid");
      console.log("Email address is not valid");
    }
  }

  //TODO: aggiunge una mail all'account
  public addEmail() {
    this.logger.log("Aggiornamento dati dell'utente");
    if (!true/*TODO: check email with regex*/) {
      this.logger.error("mail non valida");
      return;
    }
    this.otherEmails.splice(this.otherEmails.length - 1, 0, this.newEmail);
    this.db.updateUser(this.idUser,
      {
        uid: this.auth.getCurrentUser().uid,
        otherEmails: this.otherEmails
      });
    this.newEmail = "";
  }

}
