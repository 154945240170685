import { observable, computedFrom, bindable, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { Database } from 'services/database';
import { Logger } from 'helpers/logger';
import { ErrorManager } from 'helpers/errorManager';
import { Authentication } from 'services/authentication';

import { Log } from 'definitions';

@autoinject
export class LoginPage {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  @bindable email: string = "jacopofederici@ymail.com";
  @bindable password: string = "casamia1993";

  timeout: number = 5000;
  ms: number = 500;
  @bindable attempts: number = 0;

  @bindable resetPasswordEmailSent: boolean = false;

  constructor(private router: Router, private auth: Authentication) { }

  bind() {
    window.scrollTo(0, 0);
    if (this.auth.isLogged)
      this.router.navigateToRoute('dashboard');
  }

  //DOC: metodo per il login con email e password
  public login() {
    this.attempts = 0;
    if (this.email != "" && this.password != "") {
      console.log("logging in...");
      //NOTE: l'intervallo incrementa gli attempts e l'intervallo esegue l'alert al timeout
      let intervalFun = setInterval(() => this.attempts++, this.ms);
      let timeoutFun = setTimeout(() => {
        alert("Something went wrong. Check your internet or reload the page. It solve almost everything, maybe even this.");
        clearInterval(intervalFun);
        //this.errorManager.error("Timeout Login Error");
      }, this.timeout);
      this.auth.loginWithUsernameAndPassword(this.email, this.password)
        .then(() => {
          clearInterval(intervalFun);
          clearTimeout(timeoutFun);
          this.router.navigateToRoute('dashboard');
        });
    }
  }

  //DOC: invia la mail per resettare la password
  public sendEmailToResetPassword() {
    if (this.email != "") {
      this.auth.sendPasswordResetEmail(this.email);
      this.resetPasswordEmailSent = true;
    }
  }

}
