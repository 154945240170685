import { autoinject, inject, computedFrom } from 'aurelia-framework';
import { Container } from 'aurelia-dependency-injection';

import { Authentication } from 'services/authentication';

import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';

import * as Firebase from 'firebase/app';
import 'firebase/storage';

//NOTE: ogni utente ha una cartella che è l'uid dell'utente e dentro ci sono tutte le risorse dell'utente (immagini curriculum, etc)
//NOTE: le immagini dei profili sono dentro profilepictures/<uid>.<ext>

//DOC: mappa risorse locali
export interface iconPack {
  name: string;
  icons: string[];
}
export class LocalResources {
  static profile: any = {
    default: "/default/profile_black.png"
  };
  static noIcon: string = "/images/icons/no.png";
  static icons: iconPack[] = [
    {
      name: "pack1",
      icons: ["/images/icons/pack1/1.png", "/images/icons/pack1/2.png", "/images/icons/pack1/3.png",
        "/images/icons/pack1/4.png", "/images/icons/pack1/5.png", "/images/icons/pack1/6.png"]
    }, {
      name: "pack2",
      icons: ["/images/icons/pack1/1.png", "/images/icons/pack1/2.png", "/images/icons/pack1/3.png",
        "/images/icons/pack1/4.png", "/images/icons/pack1/5.png", "/images/icons/pack1/6.png"]
    }, {
      name: "pack3",
      icons: ["/images/icons/pack1/1.png", "/images/icons/pack1/2.png", "/images/icons/pack1/3.png",
        "/images/icons/pack1/4.png", "/images/icons/pack1/5.png", "/images/icons/pack1/6.png"]
    }
  ];
}
//DOC: mappa risorse esterne (Storage)
export class ExternalResources {
  //DOC: prefisso di tutte le risorse
  static prefix: string = "alpha/";
  static profile: string = ExternalResources.prefix + "profile/";
}


//DOC: classe wrapper di firebase per la gestione dello storage (Firebase)
@autoinject
export class Storage_ {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = (new Logger()).setStyle(Logger.colors.orange, Logger.colors.white).setDefaultPrefix("STORAGE");

  //DOC: oggetto storage di Firebase
  private storage: Firebase.storage.Storage;


  //DOC: percorso delle risorse nello storage
  //NOTE: path risorse dell'utente
  //public static pathCurriculumImages: string = Storage_.storagePrefix + "curriculumimages/";

  //DOC: riferimento all'Authentication di Firebase
  private _auth: Authentication = null;
  //DOC: inizializza o restituisce il riferimento all'Authentication instanziato
  private get auth(): Authentication {
    if (!this._auth) this._auth = this.container.get(Authentication);
    return this._auth;
  };

  constructor(private container: Container) {
    this.storage = Firebase.storage();
    this.logger.success("Firebase Storage", "inizializzato correttamente");
  }

  public uploadProfilePicture(uid: string, file: any, callbackOnProgress: (data: any) => any, callbackOnCompleted: (data: any) => any, context: any) {
    let n = Math.floor(Math.random() * 1000 + 1); //NOTE: nome = uid_<random number[1-10]>.<ext>
    let path = LocalResources.profile + uid + /*"_" + n + */'.' + file.name.split('.').pop();
    var uploadTask = this.storage.ref().child(path).put(file);
    uploadTask.on('state_changed',
      (snapshot) => callbackOnProgress.call(context, snapshot),
      (e) => {
        // Errors list: https://firebase.google.com/docs/storage/web/handle-errors
        var error = e as Firebase.FirebaseError; //NOTE: //if error gives a warning check here https://github.com/firebase/firebase-js-sdk/issues/1515
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;
          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => callbackOnCompleted.call(context, downloadURL))
    );
  }

}
