import { autoinject, computedFrom, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Database, Path, ChildPath, Action, Query } from 'services/database';
import { Confirm, ConfirmState, ConfirmHistoryItem, Element } from 'definitions';

import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';

import * as Utils from 'helpers/utils';

import './css/confirm-to-review-component.css';

//DOC: modello della confirm che l'utente (reviewer) deve confermare
@autoinject
export class ConfirmToReviewComponent {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  //DOC: la confirm che mostro
  @bindable confirm: Confirm;

  @bindable experience: Element;

  //DOC: indica se si stanno caricando i dati
  @bindable isLoadingData: boolean = true;

  //DOC: array di query firebase che si utilizzano nel modello
  public queries: Query[] = [];

  constructor(private db: Database, private router: Router) { }

  bind() {
    if (!this.confirm.history || this.confirm.history.length == 0)
      throw new Error("the history array for the confirm " + this.confirm.id + " is empty");
    //DOC: ordino l'array history in base alla data
    this.confirm.history.sort((i1, i2) => i1.date - i2.date);

    //caricare l'esperienza dell'utente che ha chiesta la review
    this.db.query(Path.elements).orderByKey().equalTo(this.confirm.idExperience).limitToFirst(1)
      .once(Action.onValue, (data: any) => {
        if (data.exists()) {
          this.experience = Utils.toArrayOfObjects(data.val()).map(v => v = v as Element)[0];
          //console.log(this.experience);
        }
        else {
          alert("Id of the Element not found");
          this.logger.error("Id of the Element not found");
        }
      })
  }

  //DOC: mostra l'esperienza riferita
  public viewExperience(experienceId: string) {
    alert("jump to the user cv page on his experience");
    //TODO: this.router.navigateToRoute("/:id/edit", {id:this.confirm., goto: experienceId });
    //DEVO FARE LA VISTA DEL CURRICULUM SENZA ESSERE LOGGATO COME UTENTE
  }


  public accept() {
    if (this.unchangeableState()) {
      alert('The experience is blocked!');
      return;
    }
    let text = prompt("Add a comment to notify the user", "I'm a confirm comment");
    this.db.appendHistoryConfirm(
      this.confirm.id,
      this.confirm.history.length,
      { date: (new Date()).getTime(), text: (text == null ? "" : text), state: "approved" }
    )
  }
  public reject() {
    if (this.unchangeableState()) {
      alert('The experience is blocked!');
      return;
    }
    let text = prompt("Add a comment to notify the user", "I'm a confirm comment");
    this.db.appendHistoryConfirm(
      this.confirm.id,
      this.confirm.history.length,
      { date: (new Date()).getTime(), text: (text == null ? "" : text), state: "rejected" }
    )
  }
  public incomplete() {
    if (this.unchangeableState()) {
      alert('The experience is blocked!');
      return;
    }
    let text = prompt("Add a comment to notify the user", "I'm a confirm comment");
    this.db.appendHistoryConfirm(
      this.confirm.id,
      this.confirm.history.length,
      { date: (new Date()).getTime(), text: (text == null ? "" : text), state: "incomplete" }
    )
  }

  public unchangeableState(): boolean {
    return this.lastStatus == "approved" || this.lastStatus == "rejected";
  }

  //DOC: restituiscelo lo stato della confirm
  @computedFrom('confirm.history.length')
  get lastStatus(): string {
    return this.confirm.history[this.confirm.history.length - 1].state;
  }
  //DOC: restituscie la data dell'ultimo azione riguardante la confirm
  @computedFrom('confirm.history.length')
  get lastEdit(): number {
    return this.confirm.history[this.confirm.history.length - 1].date;
  }

  //DOC: restituisce il css in base allo stato della confirm
  @computedFrom('confirm.history.length')
  get statusCss(): string {
    switch (this.lastStatus) {
      case "requested": return "secondary";
      case "incomplete": return "warning";
      case "approved": return "success";
      case "rejected": return "danger";
      default: return "";
    }
  }

}
