import { bindable } from 'aurelia-templating';
import { autoinject } from 'aurelia-framework';
import * as d3 from 'd3';
import "./css/insight-cv-page.css";

@autoinject
export class InsightCvPage {

  @bindable first: HTMLElement;
  @bindable second: HTMLElement;

  constructor() {
    window.scrollTo(0, 0);
  }

  attached() {
    //this.dochart();
    this.dochart_1();
    //this.dochart_2();
    //doChart1(this.second);
  }

  public dochart_1() {
    var data = [30, 86, 168, 281, 303, 365];
    d3.select(this.first)
      //d3.select("#chart3")
      .selectAll("div")
      .data(data)
      .enter()
      .append("div")
      .style("width", function (d) { return d + "px"; })
      .style("background", "black")
      .classed("m-1 text-white", true)
      .text(function (d) { return d; });
  }

  public dochart_2() {
    /*
    const svg = d3.select(DOM.svg(width, height));

    const x = d3.scaleLinear()
      .domain([0, m - 1])
      .range([0, width]);

    const y = d3.scaleLinear()
      .range([height, 0]);

    const z = d3.interpolateCool;

    const area = d3.area()
      .x((d, i) => x(i))
      .y0(d => y(d[0]))
      .y1(d => y(d[1]));

    function randomize() {
      const layers = stack(d3.transpose(Array.from({ length: n }, () => bumps(m, k))));
      y.domain([
        d3.min(layers, l => d3.min(l, d => d[0])),
        d3.max(layers, l => d3.max(l, d => d[1]))
      ]);
      return layers;
    }

    const path = svg.selectAll("path")
      .data(randomize)
      .enter().append("path")
      .attr("d", area)
      .attr("fill", () => z(Math.random()));

    const interval = d3.interval(() => {
      path
        .data(randomize)
        .transition()
        .duration(1500)
        .attr("d", area);
    }, 2500);

    invalidation.then(() => interval.stop());

    return svg.node();
    */
  }

}
