import { bindable, bindingMode, computedFrom, autoinject } from "aurelia-framework";

import './input-editable.css';

/*DOC: Questo componente consente di avere un testo modificabile.
  Il testo è bindato su `text` e quando termina la sua modifica viene sollevato
  L 'evento `onTextChanged` che contiene il testo finale.
  Di default `bindingMode` è `toView` quindi non c'è aggiornamento nel `model`.
  Questo comportamento è studiato per catturare il valore modificato restituito dall'evento sollevato
  nella variabile di nome `text`. 
  E' possibile modificare il comportamento specificando il tipo di binding in `two-way`. 
*/
@autoinject
export class InputEditable {
  //DOC: indica se può essere modificato, se false mostra solo il testo senza label
  @bindable canEdit: boolean = false;
  //DOC: indica se si sta modificando
  @bindable isEditing: boolean = false;

  //DOC: testo bindato
  @bindable({ defaultBindingMode: bindingMode.toView }) text: string = "";
  //DOC: testo placeholder quando il testo diventa vuoto
  @bindable placeholder: string = "";
  //DOC: testo placeholder quando il testo diventa vuoto
  @bindable placeholderTextarea: string = "";
  //DOC: testo markdown di suggerimento visibile sono in editing
  @bindable textSuggestion: string = "";
  //DOC: etichetta del campo, se "" non la mostro
  @bindable label: string = "";
  //DOC: css aggiuntivo
  @bindable css: string = "";

  //proprietà grafiche
  //DOC: numero di rows del textarea
  @bindable maxRows: number = null;

  //DOC: evento sollevato quando il testo cambia (click fuori dalla textarea)
  @bindable onTextChanged: (text: { text: string }) => void;

  constructor(public element: Element) { }

  bind() {
    if (!this.text || this.text.trim() == "")
      this.text = "";
  }

  //DOC: imposta l'altezza della textarea in base al contenuto o alle righe massime impostate
  public setTextareaHeight() {
    var textarea = this.element.querySelector("textarea") as HTMLTextAreaElement;
    if (!textarea) return; //NOTE: se non è nel DOM esco
    if (this.maxRows) {
      textarea.style.height = parseFloat(textarea.style.lineHeight) * parseFloat(textarea.style.fontSize) + "px";
    }
    else {
      textarea.style.height = "auto";
      //ta.style.rowGap
      textarea.style.height = (textarea.scrollHeight) + "px";
    }
    textarea.focus();
  }

  //DOC: ad ogni cambiamento del valore di text controllo che non sia null e non sia solo spazi
  public textChanged(_new: string, _old: string) {
    if (!_new || _new.trim() == "")
      this.text = "";
  }

  //DOC: imposta la modalità modifica bloccando la propagazione del click in caso di url all'interno della textarea
  public setEditing(s: boolean, e: any) {
    event.stopImmediatePropagation();
    this.isEditing = s;
  }


  //DOC: restituisce text oppure placeholder quando non si sta modificando il testo
  @computedFrom('text')
  get textToShow() {
    if (!this.text || this.text == "")
      return this.placeholder;
    return this.text;
  }

  //DOC: restituisce un css se text == ""
  @computedFrom('text')
  get textIsEmptyCss() {
    return (!this.text || this.text == "") ? "text-empty" : "";
  }
}
