import { PLATFORM } from 'aurelia-pal';
import { observable, computedFrom, bindable, autoinject } from 'aurelia-framework';
import { Router, RouterConfiguration } from 'aurelia-router';

import "./css/setting-cv-page.css";

@autoinject
export class SettingCvPage {
  constructor(public router: Router) {
    window.scrollTo(0, 0);
  }

  configureRouter(config: RouterConfiguration, router: Router) {
    //NOTE: il parametro `save` e `reset` servono per bindare le funzioni tra questa pagina e la route-view figlia
    config.map([
      {
        route: ['', 'style'], name: 'setting/style',
        moduleId: PLATFORM.moduleName('pages/CvSubpages/SettingCvSubpages/StylePage'), nav: true, title: 'Style',
        reset: () => { }
      },
      {
        route: ['format'], name: 'setting/format',
        moduleId: PLATFORM.moduleName('pages/CvSubpages/SettingCvSubpages/FormatPage'), nav: true, title: 'Format',
        reset: () => { }
      }
    ]);
    this.router = router;
  }

  bind() { }

  //DOC: chiama la funzione `save()` della route-view attiva
  public save() {
    this.router.currentInstruction.config.save();
  }

  //DOC: chiama la funzione `reset()` della route-view attiva
  public reset() {
    this.router.currentInstruction.config.reset();
  }

  /*scrollToSection(section: string) {
    if (this.element)
      this.element.querySelector(section).scrollIntoView({ block: 'center', behavior: 'smooth' });
  }*/

}
