import { Database } from 'services/database';
import { Authentication } from 'services/authentication';
import { autoinject, computedFrom, bindable } from 'aurelia-framework';

import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';

import * as zxcvbn from 'zxcvbn';

@autoinject
export class AccountPage {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  //DOC: dati account
  @bindable email: string = "";
  @bindable oldPassword: string = "";
  @bindable newPassword1: string = "";
  @bindable newPassword2: string = "";

  //DOC: indica se l'utente ha inserito correttamente la password corrente per poi settare la nuova
  @bindable reauthenticated: boolean = false;

  //DOC: indica la forza della password ottenuta da zxcvbn
  @bindable passwordStrength: any = null;

  constructor(private auth: Authentication, private db: Database) { }

  bind() {
    this.email = this.auth.getCurrentUser().email;
    window.scrollTo(0, 0);
  }

  public newPassword1Changed(_new: string, _old: string) {
    //NOTE: calcolo la forza della password con il plugin zxcvbn
    this.passwordStrength = zxcvbn(_new);
  }

  //DOC: metodo che verifica la password corrente prima di importare la nuova
  public reAuthenticate() {
    this.auth.reAuthenticateUser(this.email, this.oldPassword)
      .then(_ => { console.log("reauthenticated with success"); this.reauthenticated = true; })
      .catch(_ => console.log("got some trouble"));
  }

  //DOC: metodo per aggiornare la password
  public updatePassword() {
    if (this.newPassword1 != this.newPassword2) return;
    if (zxcvbn(this.newPassword1).score >= 3) {
      console.log("Aggiornamento password di ", this.email, " di ", this.oldPassword, " con ", this.newPassword2);
      this.auth.updatePassword(this.email, this.oldPassword, this.newPassword2)
        .then(_ => {
          alert("Password correctly changed!");
          this.reauthenticated = false;
        });
    }
    else {
      alert("The password is not enough strong");
      console.log("password not enough strong");
    }
  }

  //DOC: metodo per inviare la mail per il reset della password
  public sendEmailToResetPassword() {
    this.auth.sendPasswordResetEmail(this.email);
    alert(`We've sent and email to your main email address (${this.email}) to reset the password.`);
  }

  //DOC: metodo eliminare completamente un account
  public deleteAccount() {
    alert("This function is currently not implemented. Contact the support to perform this operation");
  }

  //DOC: restituisce le classi css in base alla forza della password inserita
  @computedFrom('passwordStrength.score')
  get passwordStrengthCss() {
    if (!this.passwordStrength) return "bg-danger";
    switch (this.passwordStrength.score) {
      case 0:
      case 1: return "bg-danger";
      case 2: return "bg-warning";
      case 3: return "bg-warning";
      case 4: return "bg-success";
    }
    return "bg-danger";
  }
  //DOC: restituisce la percentuale della forza della password inserita
  @computedFrom('passwordStrength.score')
  get passwordStrengthPercentage() {
    let p = 100 / 3;
    if (!this.passwordStrength) return p;
    return this.passwordStrength.score * p + p;
  }
  //DOC: restituisce la stringa con gli warning e suggestions sulla password inserita
  @computedFrom('passwordStrength.feedback.warning', 'passwordStrength.feedback.suggestions')
  get suggestions(): string {
    //codice brutto!
    if (!this.passwordStrength) return "";
    let warn = this.passwordStrength.feedback.warning.trim();
    warn = warn == "" || warn[warn.length - 1] == "." ? warn : warn + ". ";
    let sugg = this.passwordStrength.feedback.suggestions.join("").trim();
    if (this.passwordStrength.feedback.suggestions.length > 1)
      sugg = this.passwordStrength.feedback.suggestions.join(". ").trim();
    sugg = sugg == "" || sugg[sugg.length - 1] == "." ? sugg : sugg + ".";
    let fin = (warn + sugg).replace(".,", ". ");
    return (fin != "" ? fin : "Perfect");
  }
}
