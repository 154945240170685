import { observable, computedFrom, bindable, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { Database } from 'services/database';

import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';

@autoinject
export class _404Page {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  router: Router;

  constructor() {
    window.scrollTo(0, 0);
  }

  bind() {}

}
