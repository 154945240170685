import { observable, computedFrom, bindable, autoinject } from 'aurelia-framework';
import { LocalResources, iconPack } from 'services/storage';
import { CurriculumStyle, Category, Element as Elem, Functions } from 'definitions';

import "./css/multiple-code-page.css";

@autoinject
export class MultipleCodePage {

  constructor(public element: Element) { }

  bind() { }

}
