import { computedFrom, bindable, autoinject } from 'aurelia-framework';
import { Router, RouteConfig, NavigationInstruction } from 'aurelia-router';
import { ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { BootstrapFormRenderer } from 'helpers/validationFormRender';
import { Confirm, Element } from 'definitions';

import { ErrorManager } from 'helpers/errorManager';
import { Logger } from 'helpers/logger';

import { Query, Database, Path, ChildPath, Action } from 'services/database';
import { Functions } from 'services/functions';
import { Authentication } from 'services/authentication';

import * as Utils from 'helpers/utils';
import * as Moment from 'moment';

import "./css/confirms-list-page.css"

@autoinject
export class ConfirmslistPage {
  //DOC: gestore degli errori
  private errorManager: ErrorManager = new ErrorManager();
  //DOC: gestore della console
  private logger: Logger = new Logger();

  //DOC: id del curriculum ottenuto dal parametro url
  private idCv: string = "";

  //DOC: array di confirms che mostro nella pagina
  @bindable confirms: Confirm[];

  @bindable requests: Confirm[];

  //DOC: nuova confirm che si vuole aprire
  @bindable newConfirmRequest: Confirm;
  //DOC:
  @bindable textConfirmRequest: string;

  @bindable emailReviewer: string;
  @bindable usersResults: any;

  //DOC: esperienza (Element) che si vuole far confermare
  @bindable experience: Element;

  //DOC: array di query firebase che si utilizzano nel modello
  public queries: Query[] = [];

  //DOC: oggetto controller della validazione di forms
  public validationController: ValidationController;

  //DOC: stato e data per cui filtrare
  filterBy = {
    states: [{ name: "open", value: 2 }, { name: "close", value: 1 }, { name: "all", value: 0 }],
    dates: [{ name: "this month", value: 30 }, { name: "last 3 months", value: 90 }, { name: "all", value: 0 }],
    selectedState: { name: "all", value: 0 },
    selectedDate: { name: "all", value: 0 }
  }

  constructor(private auth: Authentication, private db: Database, private funct: Functions, private router: Router, private controllerFactory: ValidationControllerFactory) {
    this.validationController = controllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  activate(params: any, routeConfig: RouteConfig, navigationInstruction: NavigationInstruction) {
    if (params.id != null) {
      this.idCv = params.id;
    }
    else {
      alert("Id Cv not found");
      this.logger.error("Id Cv not found");
      return;
    }
  }

  bind() {
    //DOC: sottoscrivo eventi: onChanged su Element
    this.queries = [
      this.db.query(Path.confirms).orderByChild(ChildPath.idCurriculum).equalTo(this.idCv),
      this.db.query(Path.confirms).orderByChild(ChildPath.idReviewer).equalTo(this.auth.getCurrentUser().uid)];
    this.queries[0].on(Action.onValue, (data: any) => this.onConfirmsReading.call(this, data));
    this.queries[1].on(Action.onValue, (data: any) => this.onRequestsReading.call(this, data));
    window.scrollTo(0, 0);
  }

  unbind() {
    //DOC: disinscrivo tutti gli eventi
    this.queries.forEach(q => q.off());
  }

  //DOC: callback alla lettura delle confirms del database
  public onConfirmsReading(data: any) {
    //console.log("confirms: ", data.val());
    this.confirms = Utils.toArrayOfObjects(data.val()).map(v => v = v as Confirm);
  }

  //DOC: callback alla lettura delle requests del database
  public onRequestsReading(data: any) {
    //console.log("requests: ", data.val());
    this.requests = Utils.toArrayOfObjects(data.val()).map(v => v = v as Confirm);
  }

  //DOC: //TODO:
  @computedFrom('confirms.length', 'filterBy.selectedState', 'filterBy.selectedDate')
  get filteredConfirms() {
    if (this.confirms == null) return [];
    return this.confirms
      .filter(r => {
        let s = r.history[r.history.length - 1].state;
        switch (this.filterBy.selectedState.value) {
          case 2: return s == "requested" || s == "incomplete";
          case 1: return s == "approved" || s == "rejected";
          default: return true;
        }
      })
      .filter(r => {
        let dConfirm = Moment(r.history[r.history.length - 1].date),
          dselected = Moment().subtract(this.filterBy.selectedDate.value, 'days'),
          today = Moment();
        return Moment(dConfirm).isBetween(dselected, today) || this.filterBy.selectedDate.value == 0;

      });
  }

  public emailReviewerChanged(_n, _o) {
    console.log(_n, _o);
    if (this.emailReviewer == "")
      return;
    this.funct.httpsCallable("getUserByEmail_onCall")
      ({ email: this.emailReviewer })
      .then((result) => {
        console.log(result, result.data);
      });
  }
}


//DOC: regole di validazione della form per la creazione di una 
ValidationRules
  .ensure("hello").required()
  .on(ConfirmslistPage);

